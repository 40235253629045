import { createReducer } from '@reduxjs/toolkit';
import {
  SerializedPair,
  addSerializedPair,
  removeSerializedPair,
  updateUserExpertMode,
  updateUserSlippageTolerance,
  updateUserDeadline,
  updateUserSwapChartMode, 
  updateGasPrice,
  addPinnedToken,
  removePinnedToken,
} from './actions';
import { DEFAULT_DEADLINE_FROM_NOW, INITIAL_ALLOWED_SLIPPAGE } from '~/constants';
import { GAS_PRICE_GWEI } from '../types';
import { ChainId } from '@baseswapfi/sdk-core';
import { DEFAULT_PINNED_TOKENS } from '~/constants/tokens';

const currentTimestamp = () => new Date().getTime();

type ChainIdToTokens = Record<ChainId | number, Record<string, string>>;

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number;
  timestamp: number;
  userSwapChartMode: boolean; 
  userExpertMode: boolean;
  userShowRoute: boolean;

  // only allow swaps on direct pairs
  userSingleHopOnly: boolean;

  // user defined slippage tolerance in bips, used in all txns
  userSlippageTolerance: number;

  // deadline set by user in minutes, used in all txns
  userDeadline: number;

  pairs: {
    [chainId: number]: {
      // keyed by token0Address:token1Address
      [key: string]: SerializedPair;
    };
  };

  userZapDisabled: boolean;
  gasPrice: string;
  pinnedTokens: ChainIdToTokens;
}

function pairKey(token0Address: string, token1Address: string) {
  return `${token0Address};${token1Address}`;
}

const getInitialGasPrice = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('selectedGasPrice') || GAS_PRICE_GWEI.default;
  }

  return GAS_PRICE_GWEI.default;
};

const getInitialSlippageTolerance = () => {
  if (typeof window !== 'undefined') {
    return Number(localStorage.getItem('selectedSlippageTolerance')) || INITIAL_ALLOWED_SLIPPAGE;
  }
  return INITIAL_ALLOWED_SLIPPAGE;
};

const getInitialExpertMode = () => {
  if (typeof window !== 'undefined') {
    const convertedBoolean = localStorage.getItem('expertMode') === 'true';
    return localStorage.getItem('expertMode') ? convertedBoolean : false;
  }
  return false;
};

const getInitialDeadline = () => {
  if (typeof window !== 'undefined') {
    return Number(localStorage.getItem('userTransactionTTL')) || DEFAULT_DEADLINE_FROM_NOW;
  }
  return DEFAULT_DEADLINE_FROM_NOW;
};

const getPinnedTokens = () => {
  if (typeof window !== 'undefined') {
    const PINNED_TOKENS = 'userPinnedTokens';
    return localStorage.getItem(PINNED_TOKENS)
      ? JSON.parse(localStorage.getItem(PINNED_TOKENS))
      : DEFAULT_PINNED_TOKENS;
  }
  return undefined;
};


const getInitialSwapChartMode = () => {
  if (typeof window !== 'undefined') {
    const storedMode = localStorage.getItem('userSwapChartMode');
    return storedMode ? storedMode === 'true' : true; 
  }
  return true;
};

export const userInitialState: UserState = {
  userShowRoute: true,
  userSingleHopOnly: false,
  userSlippageTolerance: getInitialSlippageTolerance(),
  userDeadline: getInitialDeadline(),
  pairs: {},
  userZapDisabled: false,
  gasPrice: getInitialGasPrice(),
  timestamp: currentTimestamp(),
  userExpertMode: getInitialExpertMode(),
  pinnedTokens: getPinnedTokens(),
  userSwapChartMode: getInitialSwapChartMode(), 
};

const PINNED_TOKENS = 'userPinnedTokens';

export default createReducer(userInitialState, (builder) => {
  builder
    .addCase(updateUserExpertMode, (state, action) => {
      state.userExpertMode = action.payload.userExpertMode;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserSwapChartMode, (state, action) => {
      state.userSwapChartMode = action.payload.userSwapChartMode;
      localStorage.setItem('userSwapChartMode', action.payload.userSwapChartMode.toString());
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserSlippageTolerance, (state, action) => {
      state.userSlippageTolerance = action.payload.userSlippageTolerance;
    })
    .addCase(updateGasPrice, (state, action) => {
      state.gasPrice = action.payload.gasPrice;
    })
    .addCase(updateUserDeadline, (state, action) => {
      state.userDeadline = action.payload.userDeadline;
      state.timestamp = currentTimestamp();
    })
    .addCase(addSerializedPair, (state, { payload: { serializedPair } }) => {
      if (
        serializedPair.token0.chainId === serializedPair.token1.chainId &&
        serializedPair.token0.address !== serializedPair.token1.address
      ) {
        const { chainId } = serializedPair.token0;
        state.pairs[chainId] = state.pairs[chainId] || {};
        state.pairs[chainId][
          pairKey(serializedPair.token0.address, serializedPair.token1.address)
        ] = serializedPair;
      }
      state.timestamp = currentTimestamp();
    })
    .addCase(
      removeSerializedPair,
      (state, { payload: { chainId, tokenAAddress, tokenBAddress } }) => {
        if (state.pairs[chainId]) {
          // just delete both keys if either exists
          delete state.pairs[chainId][pairKey(tokenAAddress, tokenBAddress)];
          delete state.pairs[chainId][pairKey(tokenBAddress, tokenAAddress)];
        }
        state.timestamp = currentTimestamp();
      },
    )
    .addCase(addPinnedToken, (state, { payload: { chainId, token } }) => {
      // prob need to fix this
      // const tokenAddress = token.isNative ? NATIVE : token.address.toLowerCase();
      const tokenAddress = token.address.toLowerCase();
      const newPinnedTokens = {
        ...state.pinnedTokens,
        [chainId]: {
          ...state.pinnedTokens[chainId],
          [tokenAddress]: tokenAddress,
        },
      };
      localStorage.setItem(PINNED_TOKENS, JSON.stringify(newPinnedTokens));
      state.pinnedTokens = { ...newPinnedTokens };
    })
    .addCase(removePinnedToken, (state, { payload: { chainId, token } }) => {
      // prob need to fix this
      // const tokenAddress = rptoken.isNative ? NATIVE : rptoken.address.toLowerCase();
      const chainWisePinnedTokens = state.pinnedTokens;
      delete chainWisePinnedTokens[chainId][token.address];
      localStorage.setItem(PINNED_TOKENS, JSON.stringify(chainWisePinnedTokens));
      state.pinnedTokens = { ...chainWisePinnedTokens };
    });
});
