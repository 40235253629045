import { ChakraTheme } from '@chakra-ui/theme';
import { modeLight } from './colors/modeLight';
import { modeDark } from './colors/modeDark';
import { modeButtons } from './buttons';
import { modeModal } from './modeModal';
import { modeShadows } from './shadows';

export const primary = '#DFFE00';

export const modeTheme = (colorMode: 'light' | 'dark'): Partial<ChakraTheme> => ({
  styles: {
    global: {
      html: {},
      body: {      
        color: '#ffffff',
        backgroundColor: '#000',
      },
    },
  },
    fonts: {
    heading: `'ChakraPetch', 'Exo 2', Roboto, "Helvetica Neue", Arial, sans-serif`,
    body: `'ChakraPetch', 'Exo 2', Roboto, "Helvetica Neue", Arial, sans-serif`,
  },

  colors: colorMode === 'light' ? modeLight : modeDark,
  shadows: colorMode === 'light' ? modeShadows.light : modeShadows.dark, 

  components: {
    Button: {
      ...modeButtons, 
      variants: {
        ...modeButtons.variants, 
      },
    },
    Modal: modeModal,
  }})

