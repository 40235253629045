import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys)

const baseStyle = definePartsStyle({
 control: {
  padding: 3, 
  bg: 'outsetComponent',
  boxSize: '2px', 
  borderWidth: '1px', 
  borderColor: 'darkborder',
  _checked: {
    bg: 'highlight',
    borderColor: 'text.100',
  }, 
  _hover: { 
   bg: 'highlight', 
  }
}, 
icon: {
 size: '2px'
}
})

const variantone = definePartsStyle({
  control: {
    padding: '8px', 
    bg: 'outsetComponent',
    boxSize: '2px', 
    _checked: {
      bg: 'highlight',
      borderColor: 'darkborder', 
      borderWidth: '2px', 
    }, 
    _hover: { 
     bg: 'highlight', 
    }
  }, 
  icon: {
   size: '2px'
  }
})

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { variantone }
})