import { baseGradients } from './baseGradients';
import { invisiblegradients } from './invisiblegradients';

export const baseLight = {
  ...baseGradients,
  ...invisiblegradients.light,
  primary: '#0154fe',
  highlight: '#00ffff',
  navbarselected: '#0154fe',
  tabselected: '#fff',
  createposition: '#000',

  tokeninputswap: '#eee',

  layoutbg: 'transparent',
  uibackground: baseGradients.radial,

  outsetComponent: 'linear-gradient(#fffff0, rgba(255, 250, 245, 1))',
  insetComponent: 'linear-gradient(135deg, rgba(255, 253, 245, 1), rgba(255, 245, 240, 1))',
  dashboardshell: baseGradients.radial,
  swaptokeninput: 'linear-gradient(#fffff0, rgba(255, 250, 245, 1))',

  navbarbg: 'linear-gradient(#fffff0, rgba(255, 250, 245, 1))',
  navbarborder: 'rgba(0, 0, 0, 0.9)',
  subnav: 'transparent',

  tablebg: 'rgba(245, 242, 244, 1)',

  automated: 'linear-gradient(to right, #000, #0154fe, #00ffff, #00ffff, #0154fe, #000)',
  border: '#a1a1a1',
  border2: 'rgba(30, 30, 30)',
  // uibackground: 'linear-gradient(135deg, rgba(249, 246, 238, 1), #DEE4EA, #fffff0)',
  insetComponentDropdown: 'linear-gradient(135deg, rgba(255, 253, 245, 1), rgba(255, 245, 240, 1))',

  settingstextgradient: 'linear-gradient(135deg, #0154fe 0%, #4d8fff 100%) ',
  mastheadtextgradient: `linear-gradient(
    135deg,
      #0154fe 0%,
      #4d8fff 25%,
      #80b0ff 100%
  )`,

  dextone: {
    100: '#0B76FF',
    150: '#2585FF',
    200: '#3F94FF',
    250: '#59A3FF',
    300: '#73B2FF',
    400: '#8DC1FF',
    500: '#A7D1FF',
    600: '#C1E0FF',
    700: '#D6EBFF',
    800: '#E6F3FF',
    900: '#F0F8FF',
  },

  // 100 dark, 200 light
  homecard: {
    100: 'linear-gradient(#fffff0, rgba(255, 250, 245, 1))',
    200: 'linear-gradient(to right, #0B76FF, #f0f8ff)',
  },
};
