// TODO(WEB-2024)
'use client';

import { PopoverProps, Tooltip } from '@chakra-ui/react';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import noop from '~/lib/util/noop';
import { TooltipOverlay } from './Overlay';
export enum TooltipSize {
  ExtraSmall = '200px',
  Small = '256px',
  Large = '400px',
}
const getPaddingForSize = (size: TooltipSize) => {
  switch (size) {
    case TooltipSize.ExtraSmall:
      return '8px';
    case TooltipSize.Small:
      return '12px';
    case TooltipSize.Large:
      return '16px 20px';
    default:
      return '14px';
  }
};

// Do not pass through PopoverProps. Prefer higher-level interface to control MouseoverTooltip.
type MouseoverTooltipProps = Omit<PopoverProps, 'content' | 'show'> & PropsWithChildren<{
  text: ReactNode;
  size?: TooltipSize;
  disabled?: boolean;
  timeout?: number;
  placement?: PopoverProps['placement'];
  onOpen?: () => void;
  useOverlay?: boolean;
  boxShadow?: string;
}>;
export function MouseoverTooltip({
  text,
  disabled,
  children,
  onOpen,
  timeout,
  useOverlay = false,
  boxShadow = 'none',
  ...rest
}: MouseoverTooltipProps) {
  const [show, setShow] = useState(false);
  const open = () => {
    setShow(true);
    onOpen?.();
  };
  const close = () => setShow(false);
  useEffect(() => {
    if (show && timeout) {
      const tooltipTimer = setTimeout(() => {
        setShow(false);
      }, timeout);
      return () => clearTimeout(tooltipTimer);
    }
  }, [timeout, show]);
  return <>
      {/* {show && useOverlay && <TooltipOverlay />} */}
      <Tooltip {...rest} bg="outsetComponent" onOpen={open} onClose={close} boxShadow={show ? boxShadow : 'none'} isDisabled={disabled} isOpen={show} label={disabled ? null : text} data-sentry-element="Tooltip" data-sentry-source-file="MouseOverTooltip.tsx">
        <div onMouseEnter={disabled ? noop : open} onMouseLeave={disabled || timeout ? noop : close}>
          {children}
        </div>
      </Tooltip>
    </>;
}