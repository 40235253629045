import { createAction } from '@reduxjs/toolkit';
import { MintField } from '../actions';

export enum Bound {
  LOWER = 'LOWER',
  UPPER = 'UPPER',
}

export const typeInput = createAction<{
  field: MintField;
  typedValue: string;
  noLiquidity: boolean;
}>('mintV3/typeInputMint');
export const typeStartPriceInput = createAction<{ typedValue: string }>(
  'mintV3/typeStartPriceInput',
);
export const typeLeftRangeInput = createAction<{ typedValue: string }>('mintV3/typeLeftRangeInput');
export const typeRightRangeInput = createAction<{ typedValue: string }>(
  'mintV3/typeRightRangeInput',
);
export const typeLowTickInput = createAction<{ typedValue: string }>('mintV3/typeLowTickInput');
export const typeHighTickInput = createAction<{ typedValue: string }>('mintV3/typeHighTickInput');
export const resetMintState = createAction<void>('mintV3/resetMintState');
export const setFullRange = createAction<void>('mintV3/setFullRange');
