import { BoxProps, Text, useTheme } from '@chakra-ui/react';
import { ReactNode, forwardRef, memo } from 'react';
interface NavbarLinkProps extends BoxProps {
  selected?: boolean;
  isActive?: boolean;
  children?: ReactNode;
}
const NavbarLink = memo(forwardRef<HTMLDivElement, NavbarLinkProps>(({
  isActive,
  selected = false,
  children,
  ...rest
}, ref) => {
  const theme = useTheme();
  return <Text ref={ref} {...rest} cursor="pointer" whiteSpace="nowrap" textAlign="center" userSelect="none" fontWeight={selected ? '500' : '500'} color={selected ? 'navbarselected' : 'text.100'} textDecoration={selected ? 'underline' : 'none'} zIndex={1} fontSize={{
    base: '24px',
    lg: '15px'
  }} _hover={{
    color: 'text.100',
    textDecoration: 'underline',
    opacity: 0.8
  }} _active={{
    color: 'text.100'
  }}>
          {children}
        </Text>;
}));
NavbarLink.displayName = 'NavbarLink';
export default NavbarLink;