import { ChainId, Token } from '@baseswapfi/sdk-core';
import { CHAIN_LP_TOKEN_INFO, Pair } from '@baseswapfi/v2-sdk';
import { flatMap } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GqlToken, useGetNftPoolsQuery } from '~/apollo/generated/graphql-codegen-generated';
import { useGetTokens } from '~/lib/global/useToken';
import { AppState, useAppDispatch } from '..';
import { GAS_PRICE_GWEI } from '../types';
import {
  addPinnedToken,
  removePinnedToken,
  setZapDisabled,
  updateGasPrice,
  updateUserDeadline,
  updateUserExpertMode,
  updateUserSlippageTolerance,
  updateUserSwapChartMode,
} from './actions';
import { useChainId } from 'wagmi';

export function useZapModeManager() {
  const dispatch = useAppDispatch();
  const zapEnabled = useSelector<AppState, AppState['user']['userZapDisabled']>(
    (state) => !state.user.userZapDisabled,
  );

  const setZapEnable = useCallback(
    (enable: boolean) => {
      dispatch(setZapDisabled(!enable));
    },
    [dispatch],
  );

  return [zapEnabled, setZapEnable] as const;
}

export function useGasPrice(): string {
  const chainId = useChainId();
  const userGas = useSelector<AppState, AppState['user']['gasPrice']>(
    (state) => state.user.gasPrice,
  );
  return chainId === ChainId.BASE || chainId === ChainId.MODE ? userGas : GAS_PRICE_GWEI.default;
}

export function useIsExpertMode(): boolean {
  return useSelector<AppState, AppState['user']['userExpertMode']>(
    (state) => state.user.userExpertMode,
  );
}

export function useExpertModeManager(): [boolean, () => void] {
  const dispatch = useAppDispatch();
  const expertMode = useSelector<AppState, AppState['user']['userExpertMode']>(
    (state) => state.user.userExpertMode,
  );

  const toggleSetExpertMode = useCallback(() => {
    localStorage.setItem('expertMode', (!expertMode).toString());
    dispatch(updateUserExpertMode({ userExpertMode: !expertMode }));
  }, [expertMode, dispatch]);

  return [expertMode, toggleSetExpertMode];
}

export function useSwapChartMode(): [boolean, () => void] {
  const dispatch = useAppDispatch();
  const swapChartMode = useSelector<AppState, AppState['user']['userSwapChartMode']>(
    (state) => state.user.userSwapChartMode,
  );

  const toggleSwapChartMode = useCallback(() => {
    localStorage.setItem('userSwapChartMode', (!swapChartMode).toString());
    dispatch(updateUserSwapChartMode({ userSwapChartMode: !swapChartMode }));
  }, [swapChartMode, dispatch]);

  return [swapChartMode, toggleSwapChartMode];
}

export function useUserSlippageTolerance(): [number, (slippage: number) => void] {
  const dispatch = useAppDispatch();
  const userSlippageTolerance = useSelector<AppState, AppState['user']['userSlippageTolerance']>(
    (state) => state.user.userSlippageTolerance,
  );

  const setUserSlippageTolerance = useCallback(
    (slippage: number) => {
      localStorage.setItem('selectedSlippageTolerance', slippage.toString());
      dispatch(updateUserSlippageTolerance({ userSlippageTolerance: slippage }));
    },
    [dispatch],
  );

  return [userSlippageTolerance, setUserSlippageTolerance];
}

export function useGasPriceManager(): [string, (userGasPrice: string) => void] {
  const dispatch = useAppDispatch();
  const userGasPrice = useGasPrice();

  const setGasPrice = useCallback(
    (userGasPrice: string) => {
      localStorage.setItem('selectedGasPrice', userGasPrice);
      dispatch(updateGasPrice({ gasPrice: userGasPrice }));
    },
    [dispatch],
  );

  return [userGasPrice, setGasPrice];
}

export function useUserTransactionTTL(): [number, (ttl: number) => void] {
  const dispatch = useAppDispatch();
  const userDeadline = useSelector<AppState, AppState['user']['userDeadline']>((state) => {
    return state.user.userDeadline;
  });

  const setUserDeadline = useCallback(
    (deadline: number) => {
      localStorage.setItem('userTransactionTTL', deadline.toString());
      dispatch(updateUserDeadline({ userDeadline: deadline }));
    },
    [dispatch],
  );

  return [userDeadline, setUserDeadline];
}

/**
 * Given two tokens return the liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 */
export function toV2LiquidityToken([tokenA, tokenB]: [Token, Token]): Token {
  const lpInfo = CHAIN_LP_TOKEN_INFO[tokenA.chainId];
  return new Token(tokenA.chainId, Pair.getAddress(tokenA, tokenB), 18, lpInfo.symbol, lpInfo.name);
}

/**
 * Returns all the pairs of tokens that are tracked by the user for the current chain ID.
 */
export function useTrackedTokenPairs(): [Token, Token][] {
  const chainId = useChainId();
  const { tokensMapping, getTokenInstance, getLiquidityTrackingTokens } = useGetTokens();

  // const { lpData } = useUserWalletLPBalances();

  // const { data } = useGetPoolsQuery({
  //   variables: {
  //     chainId,
  //     where: {
  //       idIn: lpData.map((p) => `${chainId}-${p.pair.id}`),
  //     },
  //   },
  //   pollInterval: 30000,
  // });

  const { data } = useGetNftPoolsQuery({
    variables: {
      chainId,
    },
    pollInterval: 30000,
  });

  // const farmPairs: [Token, Token][] = useMemo(
  //   () =>
  //     lpData.map((p) => [getTokenInstance(p.pair.token0.id), getTokenInstance(p.pair.token0.id)]),
  //   [lpData],
  // );

  // const farmPairs: [Token, Token][] = useMemo(
  //   () =>
  //     data?.poolGetPools.map((p) => [
  //       getTokenInstance(p.token0.address),
  //       getTokenInstance(p.token0.address),
  //     ]),
  //   [data],
  // );

  const farmPairs: [Token, Token][] = useMemo(
    () =>
      data?.getNFTPools.map((p) => [
        getTokenInstance(p.v2Pool.token0.address),
        getTokenInstance(p.v2Pool.token1.address),
      ]),
    [data],
  );

  const trackingTokens = getLiquidityTrackingTokens();
  // pairs for every token against every base
  const generatedPairs: [Token, Token][] = useMemo(() => {
    if (chainId) {
      const mapped = flatMap(Object.keys(tokensMapping), (tokenAddress) => {
        const token = tokensMapping[tokenAddress];
        // for each token on the current chain,
        return (
          // loop through all bases on the current chain
          // to construct pairs of the given token with each base
          trackingTokens
            .map((base) => {
              if (base.address.toLowerCase() === token.address.toLowerCase()) {
                return null;
              }
              return [base, token];
            })
            .filter((p): p is [Token, Token] => p !== null)
        );
      });

      return mapped;
    }

    return [];
  }, [tokensMapping, chainId]);

  // pairs saved by users
  const savedSerializedPairs = useSelector<AppState, AppState['user']['pairs']>(
    ({ user: { pairs } }) => pairs,
  );

  const userPairs: [Token, Token][] = useMemo(() => {
    if (!chainId || !savedSerializedPairs) return [];
    const forChain = savedSerializedPairs[chainId];
    if (!forChain) return [];

    return Object.keys(forChain).map((pairId) => {
      return [forChain[pairId].token0, forChain[pairId].token1];
    });
  }, [savedSerializedPairs, chainId]);

  const combinedList = useMemo(
    () =>
      userPairs
        .concat(generatedPairs)
        .concat(farmPairs)
        .filter((up) => up !== undefined),
    [generatedPairs, userPairs, farmPairs],
  );

  return useMemo(() => {
    if (!combinedList || !combinedList[0]) return [];
    // dedupes pairs of tokens in the combined list
    const keyed = combinedList.reduce<{ [key: string]: [Token, Token] }>(
      (memo, [tokenA, tokenB], i) => {
        if (!tokenA || !tokenB) {
          // console.log(`Missing token for pair`);
          // console.log(i);
          // console.log(tokenA);
          // console.log(tokenB);

          return memo;
        }

        //const sorted = tokenA?.sortsBefore(tokenB);
        const sorted = tokenA.address < tokenB.address;
        const key = sorted
          ? `${tokenA?.address}:${tokenB?.address}`
          : `${tokenB?.address}:${tokenA?.address}`;

        if (memo[key]) return memo;

        memo[key] = sorted ? [tokenA, tokenB] : [tokenB, tokenA];

        return memo;
      },
      {},
    );

    return Object.keys(keyed).map((key) => keyed[key]);
  }, [combinedList]);
}

export function useAddPinnedToken(): (chainID: ChainId, token: GqlToken) => void {
  const dispatch = useAppDispatch();
  const setAddPinnedToken = useCallback(
    (chainId: ChainId, token: GqlToken) => {
      dispatch(addPinnedToken({ chainId, token }));
    },
    [dispatch],
  );

  return setAddPinnedToken;
}

export function useRemovePinnedToken(): (chainID: ChainId, token: GqlToken) => void {
  const dispatch = useAppDispatch();
  const setRemovePinnedToken = useCallback(
    (chainId: ChainId, token: GqlToken) => {
      dispatch(removePinnedToken({ chainId, token }));
    },
    [dispatch],
  );

  return setRemovePinnedToken;
}

export function usePinnedTokens() {
  return useSelector<AppState, AppState['user']['pinnedTokens']>(
    (state) => state.user.pinnedTokens,
  );
}
