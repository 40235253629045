import BlueRadial from "~/app/components/icons/DeFi/Misc/BlueRadial";

export const invisiblegradients = {

 light: {
    invisiblegradienthero: `
       repeating-conic-gradient(
         from 0deg,
         rgba(1, 84, 254, 0.2) 0deg 2deg, 
         rgba(255, 255, 240, 0.01) 2deg 15deg
       ),
       radial-gradient(
         circle at 50% 50%,
         rgba(1, 84, 254, 0.25) 0%,
          rgba(1, 84, 254, 0.1) 20%,
         rgba(255, 255, 240, 0) 40%
       )`, 
  
     invisiblegradientcard: `
         repeating-conic-gradient(
           from 0deg,
           rgba(1, 84, 254, 0.15) 1deg 1deg, 
           rgba(255, 255, 240, 0.01) 2deg 15deg
         )`, 


  // saving this because the middle grad should be the bg. 

  //  invisiblegradienthero: `
  //   repeating-linear-gradient(
  //   45deg,
  //   rgba(255, 253, 250, 1) 0px,
  //   rgba(255, 253, 250, 1) 1px,
  //   rgba(255, 250, 240, 1) 1px,
  //   rgba(255, 250, 240, 1) 2px
  // ),
  // linear-gradient(
  //   to bottom right,
  //   rgba(255, 253, 245, 0.8) 0%,
  //   rgba(255, 248, 235, 0.8) 50%,
  //   rgba(255, 245, 215, 0.8) 100%
  // ),

  // radial-gradient(
  //   circle at 50% 50%,
  //   rgba(255, 250, 240, 0.8) 0%,
  //   rgba(255, 248, 235, 0.6) 50%,
  //   rgba(255, 245, 230, 0.4) 100%
  // )
  //    `, 
     // the linear grad with 255, 253, 245, 0.8 should be the background. all three values. 


     // saving this because its a nice blue texture 
     // invisiblegradientframe: `
     // linear-gradient(
     //     45deg,
     //     rgba(1, 84, 254, 0.1) 0%,
     //     rgba(1, 84, 254, 0.05) 50%,
     //     rgba(1, 84, 254, 0.1) 100%
     //   ),
     //   radial-gradient(
     //     circle at 50% 0%,
     //     rgba(255, 255, 255, 0.5) 0%,
     //     rgba(255, 255, 255, 0.1) 75%,
     //     rgba(255, 255, 255, 0) 100%
     //   ),
     //   linear-gradient(
     //     to bottom right,
     //     rgba(1, 84, 254, 0.2) 0%,
     //     rgba(1, 84, 254, 0.1) 50%,
     //     rgba(1, 84, 254, 0.2) 100%
     //   ),
     //   #f0f0f0
     // `, 

   invisiblegradient: `
     repeating-conic-gradient(
       from 0deg,
       rgba(1, 84, 254, 0.2) 0deg 2deg, 
       rgba(255, 255, 240, 0.9) 2deg 15deg
       ),

    #fffff0 `,
  }, 

 dark: { 
  invisiblegradienthero: `
    repeating-conic-gradient(
        from 0deg,
        rgba(1, 84, 254, 0.2) 0deg 2deg, 
        rgba(255, 255, 240, 0.01) 2deg 15deg
      ),
    radial-gradient(
        circle at 50% 50%,
        rgba(1, 84, 254, 0.25) 0%,
         rgba(1, 84, 254, 0.1) 20%,
        rgba(255, 255, 240, 0) 40%
      ) `, 

      invisiblegradient: `
      repeating-conic-gradient(
        from 0deg,
        rgba(1, 84, 254, 0.5) 0deg 2deg, 
        rgba(255, 255, 240, 0.01) 2deg 15deg
        ),
      radial-gradient(
        circle at 50% 50%,
        rgba(1, 84, 254, 0.25) 0%,
         rgba(1, 84, 254, 0.1) 20%,
        rgba(255, 255, 240, 0) 40%
      ) `, 
 }, 
}
