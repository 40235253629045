import React from 'react';
import { Box, Text, useTheme, Divider } from '@chakra-ui/react';
interface DropdownLinkTemplateProps {
  icon?: React.ElementType;
  text: string;
}
export default function DropdownLinkTemplate({
  icon: Icon,
  text
}: DropdownLinkTemplateProps) {
  const theme = useTheme();
  return <>
      <Box textAlign="left" _hover={{
      bg: 'gray.900'
    }} display="flex" alignItems="center" justifyContent="flex-start" py="2" px="1" w="full" borderRadius="16px" data-sentry-element="Box" data-sentry-source-file="DropdownLinkTemplate.tsx">
        {Icon && <Box mr="12px" display="inline-block">
            <Icon color={theme.colors.gray[50]} />
          </Box>}
        <Text color={theme.colors.gray[50]} data-sentry-element="Text" data-sentry-source-file="DropdownLinkTemplate.tsx">{text}</Text>
      </Box>
      <Divider my="1" data-sentry-element="Divider" data-sentry-source-file="DropdownLinkTemplate.tsx" />
    </>;
}