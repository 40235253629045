import React from 'react';
import { PopoverTrigger, Flex, useDisclosure, useTheme } from '@chakra-ui/react';
import { ChevronDown } from 'react-feather';
function DropdownTrigger({
  isOpen,
  onOpen,
  onClose
}) {
  const theme = useTheme();
  return <>
      <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="DropdownTrigger.tsx">
        <Flex px="3" py="1" align="flex-end" justify="flex-end" _hover={{
        bg: 'gray.850'
      }} borderRadius="12px" aria-haspopup="menu" aria-label="Open additional links" aria-expanded={isOpen} aria-controls="popover-content" onClick={() => isOpen ? onClose() : onOpen()} data-sentry-element="Flex" data-sentry-source-file="DropdownTrigger.tsx">
          <ChevronDown size="18px" color={theme.colors.text[100]} style={{
          marginTop: '6px',
          transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.1s'
        }} data-sentry-element="ChevronDown" data-sentry-source-file="DropdownTrigger.tsx" />
        </Flex>
      </PopoverTrigger>
    </>;
}
export default DropdownTrigger;