'use client';

import Script from 'next/script';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { pageview } from '~/lib/gtagHelper/gtagHelper';
export default function GoogleAnalytics() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const measurementId = process.env.NEXT_PUBLIC_MEASUREMENT_ID;
  //moving out of dependency array 

  useEffect(() => {
    if (!measurementId) return;
    const url = pathname + (searchParams.toString() ? `?${searchParams.toString()}` : '');
    pageview(measurementId, url);
  }, [pathname, searchParams]);
  return <>
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_MEASUREMENT_ID}`} data-sentry-element="Script" data-sentry-source-file="GoogleAnalytics.tsx" />
      <Script id="google-analytics" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('consent', 'default', {
                    'analytics_storage': 'denied'
                });

                gtag('config', '${process.env.NEXT_PUBLIC_MEASUREMENT_ID}', {
                    page_path: window.location.pathname,
                });
                `
    }} data-sentry-element="Script" data-sentry-source-file="GoogleAnalytics.tsx" />
    </>;
}