import { useState } from 'react';
import { createWeb3Name } from '@web3-name-sdk/core';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
import { useWeb3React } from '~/hooks/useWeb3React';
import { useAsyncEffect } from '~/lib/util/custom-hooks';

const useDomainName = () => {
  const [domainName, setDomainName] = useState<string | null>(null);
  const [isDomainNameLoading, setDomainNameLoading] = useState(false);
  const { account, isConnected } = useWeb3React();
  const web3name = createWeb3Name();
  const networkConfig = useNetworkConfig();

  useAsyncEffect(async () => {
    try {
      if (!isConnected) return null;
      if (!networkConfig.ens.hasWebNameSupport) return null;

      const name = await web3name.getDomainName({
        address: account,
        queryTldList: [networkConfig.ens.nameServiceId],
      });
      setDomainName(name);
    } catch (error) {
      console.error('Error fetching domain name:', error);
      setDomainName(null);
    }
  }, [account, networkConfig, isConnected, web3name]);

  return { domainName, isDomainNameLoading };
};

export default useDomainName;
