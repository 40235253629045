export const baseGradients = {

radial: `

      radial-gradient(
        circle at 50% 0%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.1) 75%,
        rgba(255, 255, 255, 0) 100%
      ),
      linear-gradient(
        to bottom right,
        rgba(1, 84, 254, 0.15),
        rgba(245, 242, 244, 0.8), 
        rgba(1, 84, 254, 0.25),
        rgba(245, 242, 244, 0.8), 
        rgba(1, 84, 254, 0.15)
      ),
 
      #f0f0f0
    `,

dexgradient: {
 100: 'linear-gradient(to right, #0154fe, #37C0DF)',
 200: 'linear-gradient(to bottom, #0154fe, #37C0DF)',
 300: 'linear-gradient(to right, #0154fe, #60FE00)',
 400: 'linear-gradient(to right, #0154fe, #37C0DF 20%, #fff)',
 500: 'linear-gradient(to right, rgba(10, 29, 86, 1), #010B13)',
 600: 'linear-gradient(to left, rgba(10, 29, 86, 1), #010b13)',
}, 


invisiblegradient: `
repeating-conic-gradient(
  from 0deg,
  rgba(1, 84, 254, 0.5) 0deg 2deg, 
  rgba(255, 255, 240, 0.9) 2deg 15deg
),
radial-gradient(
  circle at 50% 50%,
  rgba(51, 170, 255, 0.2) 0%,
  rgba(1, 84, 254, 0.5) 70%,
  rgba(255, 255, 240, 0.9) 70%
),
#fffff0
`,

primarybutton: 'linear-gradient(to right, #0154fe, #37C0DF)',
buttongroup: 'linear-gradient(to bottom, #0154fe, #37C0DF)',
scrollbar: 'linear-gradient(to bottom, #0154fe, #37C0DF)', 
slidertrack: 'linear-gradient(to left, #0154fe, #37C0DF)', 

darkgradient: {
 100: 'linear-gradient(to bottom, #070707, #191919, #070707)',
 200: 'linear-gradient(to right, #090b16, rgba(10, 29, 86, 0.5), #090b16)',
 300: 'linear-gradient(to bottom, #090b16, rgba(10, 29, 86, 0.75), #090b16)',
 400: 'linear-gradient(to bottom, #111, #1b223a, #111)',
 500: 'linear-gradient(to top, #0A0A0C, #020202)',
 600: 'linear-gradient(to top, #1b223a, rgba(1, 84, 254, 0.1))',
 700: 'linear-gradient(to bottom, rgba(41, 41, 41, 0.85), rgba(2, 2, 2, 0.5))',
 800: 'linear-gradient(to right, #090b16, #191919)',
 900: 'linear-gradient(to bottom, #000, #111, #000)',
},

cardgradient: {
  100: 'radial-gradient(rgba(10, 29, 86, 0.75), #090b16)',
  200: 'linear-gradient(to bottom, #090b16, #1b223a, #090b16)',
  300: 'radial-gradient(#090b16, rgba(10, 29, 86, 0.75))',
  400: 'radial-gradient(at 10% top, rgba(10,29,86,0.75) 0%, transparent 25%), radial-gradient(ellipse at center top, rgba(10,29,86,0.75) 0%, transparent 30%), radial-gradient(at right 10%, rgba(10,29,86,0.75) 0%, transparent 20%), radial-gradient(at left center, rgba(10,29,86,0.75) 0%, transparent 30%), radial-gradient(ellipse at center center, rgba(10,29,86,0.5) 0%, transparent 30%), radial-gradient(at 100% 40%, rgba(10,29,86,0.6) 0%, transparent 25%), radial-gradient(at left bottom, rgba(10,29,86,0.75) 0%, transparent 35%), radial-gradient(at center 80%, rgba(10,29,86,0.65) 0%, transparent 25%), radial-gradient(at right bottom, rgba(10,29,86,0.75) 0%, transparent 35%)',
  500: 'radial-gradient(at 0% 50%, rgba(10,29,86,0.75) 0%, transparent 50%), radial-gradient(at 100% 50%, rgba(10,29,86,0.75) 0%, transparent 58%), radial-gradient(at 50% 0%, rgba(10,29,86,0.75) 0%, transparent 50%),  radial-gradient(at 50% 100%, rgba(10,29,86,0.75) 0%, transparent 58%)',
  600: 'linear-gradient(to right, rgba(10, 29, 86, 0.25), #090b16)',
  700: 'none', 
 },


baseswap: {
 50: '#e6f2ff',
 100: '#b3d1ff',
 200: '#80b0ff',
 300: '#4d8fff',
 400: '#1a6eff',
 500: '#0055e6',
 600: '#0044b3',
 700: '#001320',
 800: '#00224d',
 900: '#00111a',
},
}