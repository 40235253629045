export type TransactionType =
  | 'SWAP'
  | 'ADD_LIQUIDITY_V2'
  | 'ZAP_LIQUIDITY_V2'
  | 'ADD_LIQUIDITY_V3'
  | 'REMOVE_LIQUIDITY_V2'
  | 'REMOVE_LIQUIDITY_V3'
  | 'HARVEST'
  | 'STAKE'
  | 'CLAIM'
  | 'LOCK'
  | 'UNSTAKE'
  | 'APPROVE'
  | 'WRAP'
  | 'UNWRAP'
  | 'CREATE_POSITION'
  | 'INCREASE_POSITION'
  | 'DECREASE_POSITION'
  | 'BOOST_POSITION'
  | 'UNBOOST_POSITION'
  | 'CONVERT'
  | 'REDEEM'
  | 'CLAIM_VESTING'
  | 'CANCEL_VESTING'
  | 'COLLECT_FEES'
  | 'APPROVE_USAGE';

export type ToastTransactionStatus = 'PENDING' | 'CONFIRMED' | 'ERROR';

export type ToastHeadlines = Record<ToastTransactionStatus, string>;
const TRANSACTION_HEADLINES: Record<TransactionType, ToastHeadlines> = {
  SWAP: {
    PENDING: 'Swap pending',
    CONFIRMED: 'Swap confirmed',
    ERROR: 'Swap error',
  },
  ADD_LIQUIDITY_V2: {
    PENDING: 'Add V2 pending',
    CONFIRMED: 'Add V2 confirmed',
    ERROR: 'Add V2 error',
  },
  ZAP_LIQUIDITY_V2: {
    PENDING: 'Zap Liquidity Pending',
    CONFIRMED: 'Zap Liquidity Confirmed',
    ERROR: 'Zap Error',
  },
  ADD_LIQUIDITY_V3: {
    PENDING: 'Add V3 pending',
    CONFIRMED: 'Add V3 confirmed',
    ERROR: 'Add V3 error',
  },
  REMOVE_LIQUIDITY_V2: {
    PENDING: 'Remove V2 Liquidity - Pending',
    CONFIRMED: 'Remove V2 Liquidity - Confirmed',
    ERROR: 'Remove V2 error',
  },
  REMOVE_LIQUIDITY_V3: {
    PENDING: 'Remove V3 pending',
    CONFIRMED: 'Remove V3 confirmed',
    ERROR: 'Remove V3 error',
  },
  HARVEST: {
    PENDING: 'Harvest pending',
    CONFIRMED: 'Harvest confirmed',
    ERROR: 'Harvest error',
  },
  STAKE: {
    PENDING: 'Stake pending',
    CONFIRMED: 'Stake confirmed',
    ERROR: 'Stake error',
  },
  CLAIM: {
    PENDING: 'Claim pending',
    CONFIRMED: 'Claim confirmed',
    ERROR: 'Claim error',
  },
  LOCK: {
    PENDING: 'Lock pending',
    CONFIRMED: 'Lock confirmed',
    ERROR: 'Lock error',
  },
  UNSTAKE: {
    PENDING: 'Unstake pending',
    CONFIRMED: 'Unstake confirmed',
    ERROR: 'Unstake error',
  },
  APPROVE: {
    PENDING: 'Approve pending',
    CONFIRMED: 'Approve confirmed',
    ERROR: 'Approve error',
  },
  WRAP: {
    PENDING: 'Wrap pending',
    CONFIRMED: 'Wrap confirmed',
    ERROR: 'Wrap error',
  },
  UNWRAP: {
    PENDING: 'Unwrap pending',
    CONFIRMED: 'Unwrap confirmed',
    ERROR: 'Unwrap error',
  },
  CREATE_POSITION: {
    PENDING: 'Create position pending',
    CONFIRMED: 'Create position confirmed',
    ERROR: 'Unwrap error',
  },
  INCREASE_POSITION: {
    PENDING: 'Increase Position pending',
    CONFIRMED: 'Increase Position confirmed',
    ERROR: 'Increase Position error',
  },
  DECREASE_POSITION: {
    PENDING: 'Decrease Position pending',
    CONFIRMED: 'Decrease Position confirmed',
    ERROR: 'Decrease Position error',
  },
  BOOST_POSITION: {
    PENDING: 'Boost Position pending',
    CONFIRMED: 'Boost Position confirmed',
    ERROR: 'Boost Position error',
  },
  UNBOOST_POSITION: {
    PENDING: 'Unboost Position pending',
    CONFIRMED: 'Unboost Position confirmed',
    ERROR: 'Unboost Position error',
  },
  CONVERT: {
    PENDING: 'Convert pending',
    CONFIRMED: 'Convert confirmed',
    ERROR: 'Convert error',
  },
  REDEEM: {
    PENDING: 'Redeem pending',
    CONFIRMED: 'Redeem confirmed',
    ERROR: 'Redeem error',
  },
  CLAIM_VESTING: {
    PENDING: 'Claim vesting pending',
    CONFIRMED: 'Claim vesting confirmed',
    ERROR: 'Claim vesting error',
  },
  CANCEL_VESTING: {
    PENDING: 'Cancel vesting pending',
    CONFIRMED: 'Cancel vesting confirmed',
    ERROR: 'Cancel vesting error',
  },
  COLLECT_FEES: {
    PENDING: 'Collect fees pending',
    CONFIRMED: 'Collect fees confirmed',
    ERROR: 'Collect fees error',
  },
  APPROVE_USAGE: {
    PENDING: 'Approve usage pending',
    CONFIRMED: 'Approve usage confirmed',
    ERROR: 'Approve usage error',
  },
};

export function getToastTransactionHeadline(type: TransactionType, status: ToastTransactionStatus) {
  return TRANSACTION_HEADLINES[type][status] || 'Missing headline';
}
