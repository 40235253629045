import { optimismGradients } from './optimismGradients';
import { invisiblegradients } from './invisiblegradients';

export const optimismLight = {
  ...optimismGradients,
  ...invisiblegradients.light,
  primary: '#ff0420',
  navbarselected: '#ff0420',
  highlight: '#ff7075',
  tabselected: '#fff',
  createposition: '#fff',

  tokeninputswap: '#fff',

  border: '#a1a1a1',
  border2: 'rgba(51,51,51,0.2)',
  // op graphic uses #FEF0E5 or rgba(254, 240, 229, 1) and ##FFF7FC or rgba(255, 244, 248, 1 )

  navbarbg: 'linear-gradient(135deg, rgba(250, 255, 244), rgba(254, 247, 247))',
  subnav: 'linear-gradient(135deg, rgba(250, 255, 244), rgba(254, 247, 247))',
  navbarborder: 'transparent',

  outsetComponent: 'linear-gradient(135deg, rgba(250, 255, 244), rgba(254, 247, 247))',
  insetComponent: 'linear-gradient(rgba(254, 248, 242, 1), rgba(255, 248, 248, 1) )',
  swaptokeninput: 'linear-gradient(135deg, rgba(250, 255, 244), rgba(254, 247, 247))',
  insetComponentDropdown: 'linear-gradient(rgba(254, 248, 242, 1), rgba(255, 248, 248, 1) )',

  dashboardshell: ` radial-gradient(
        circle at 50% 0%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.1) 75%,
        rgba(255, 255, 255, 0) 100%
      ),
      linear-gradient(
        to bottom right,
       rgba(229, 45, 39, 0.05), 
        rgba(245, 242, 244, 0.8), 
      rgba(255, 112, 117, 0.05),
        rgba(245, 242, 244, 0.8), 
      rgba(255, 112, 117, 0.05)
      ),
 
      #f0f0f0
    `,
  // layoutbg: 'linear-gradient(135deg, hsl(50, 23%, 97%), hsl(358, 100%, 98%), hsl(50, 23%, 97%), hsl(358, 100%, 98%))',
  layoutbg: `
      radial-gradient(
        circle at 50% 0%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.1) 75%,
        rgba(255, 255, 255, 0) 100%
      ),
      linear-gradient(
        to bottom right,
        rgba(255, 100, 130, 0.05),
        rgba(254, 240, 229, 1), 
        rgba(255, 112, 117, 0.05),
        rgba(254, 240, 229, 1), 
        rgba(255, 112, 117, 0.15)
      ),
 
     rgba(254, 240, 229, 1), 
    `,

  // layoutbg: '#f5f5f0',
  uibackground: 'linear-gradient(135deg, rgba(249, 246, 238, 1), #F9FCFF, #fffff0)',

  primarybutton: 'linear-gradient(135deg, #ff3366 0%, #ff5555 50%, #ff7777 100%)',

  tablebg: 'linear-gradient(#fffff0, #f5f5f5)',
  mastheadtextgradient: 'linear-gradient(135deg, #ff7075, #e52d27)',
  settingstextgradient: `linear-gradient(135deg, #e63946 0%, #e76f51 50%, #f4a261 100%)`,

  dextone: {
    100: '#e52d27',
    150: '#ea544e',
    200: '#ef7b75',
    250: '#f4a29d',
    300: '#f9c9c4',
    400: '#fce0da',
    500: '#fde8e5',
    600: '#fef1ef',
    700: '#fef9f8',
    800: '#fffdfc',
    900: '#FFFFFF',
  },
};
