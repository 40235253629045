export const baseTextStyles = {

// this should get moved to the global config and actually reworked so its useful. 
textStyles: {
 h1: {
   fontFamily: `'ChakraPetch', 'Exo 2', Roboto, "Helvetica Neue", Arial, sans-serif`,
   fontSize: ['3.0rem'],
   lineHeight: '100%',
   fontWeight: 'bold',
   letterSpacing: '-0.1rem',
 },
 h2: {
   fontFamily: `'ChakraPetch', 'Exo 2', Roboto, "Helvetica Neue", Arial, sans-serif`,
   fontSize: ['2.7rem'],
   fontWeight: '300',
   lineHeight: '100%',
   letterSpacing: '-0.1rem',
 },
 h3: {
   fontFamily: `'ChakraPetch', 'Exo 2', Roboto, "Helvetica Neue", Arial, sans-serif`,
   fontSize: ['1.8rem'],
   fontWeight: '300',
   lineHeight: '115%',
   letterSpacing: '-0.05rem',
 },
 h4: {
   fontFamily: `'ChakraPetch', 'Exo 2', Roboto, "Helvetica Neue", Arial, sans-serif`,
   fontSize: ['1.8rem'],
   fontWeight: '300',
   lineHeight: '115%',
   letterSpacing: '-0.05rem',
 },
 h5: {
   fontFamily: `'ChakraPetch', 'Exo 2', Roboto, "Helvetica Neue", Arial, sans-serif`,
   fontSize: ['1.5rem'],
   fontWeight: '300',
   lineHeight: '115%',
   letterSpacing: '-0.03rem',
 },
 h6: {
   fontFamily: `'ChakraPetch', 'Exo 2', Roboto, "Helvetica Neue", Arial, sans-serif`,
   fontSize: ['1.2rem'],
   fontWeight: '300',
   lineHeight: '115%',
   letterSpacing: '-0.03rem',
 },
}}