
export const invisiblegradients = {
 light: {
   invisiblegradient: `
     repeating-conic-gradient(
       from 0deg,
     
       rgba(96, 254, 0, 0.25) 0deg 2deg,
       rgba(255, 255, 240, 0.9) 4deg 15deg
     ),
     radial-gradient(
       circle at 50% 50%,
       rgba(35, 35, 35, 0.2) 0%,
       rgba(223, 254, 0, 0.15) 50%,
       rgba(96, 254, 0, 0.2) 70%,
       rgba(255, 255, 240, 0.9) 70%
     ), 
     #fffff0 `,
   
   invisiblegradienthero: `
     repeating-conic-gradient(
       from 0deg,

       rgba(96, 254, 0, 0.2) 0deg 2deg,
       rgba(255, 255, 240, 0.01) 4deg 15deg
     ),
     radial-gradient(
       circle at 50% 50%,
       rgba(223, 254, 0, 0.25) 0%,
       rgba(96, 254, 0, 0.2) 20%,
       rgba(255, 255, 240, 0) 40%
     )
     `,
 },

 dark: {
   invisiblegradienthero: `
   repeating-conic-gradient(
     from 0deg,
     rgba(223, 254, 0, 0.05) 0deg 1deg,
     rgba(96, 254, 0, 0.05) 1deg 2deg,
     rgba(22, 29, 30, 0.05) 2deg 15deg
   )`,
   invisiblegradient: `
     repeating-conic-gradient(
       from 0deg,
       rgba(223, 254, 0, 0.15) 0deg 2deg,
       rgba(96, 254, 0, 0.15) 2deg 4deg,
       rgba(22, 29, 30, 0.9) 4deg 15deg
     ),
     rgba(35, 49, 66, 1) `,
 }
}