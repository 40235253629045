'use client';

import React from 'react';
import useIsLarge from './useIsLarge';
interface ResponsiveWrapperProps {
  largeChildren: React.ReactNode;
  smallChildren: React.ReactNode;
}
const ResponsiveWrapper: React.FC<ResponsiveWrapperProps> = ({
  largeChildren,
  smallChildren
}) => {
  const isLarge = useIsLarge();
  return <>{isLarge ? largeChildren : smallChildren}</>;
};
export default ResponsiveWrapper;