import { globalDropdownStyles, globalMenuItemStyles } from "./componentStyles";


export const globalClasses = {
  '.cube': {
    border: '1px',
    borderColor: 'darkborder',

    borderRadius: '24px !important',
    maxWidth: '100vw',
  },

  /////////////
  '.basicbox': { 
    background: 'cardgradient.500',
    borderRadius: '28px !important',
    maxWidth: '100vw',
    boxShadow: {
      base: '0 8px 8px 1px black',
      lg: `0 0 8px -4px primary, 0 0 3px 1px darkborder`,
    },
  }, 

  '.chakra-ui-light .basicbox': {

    border: '2px solid',
    borderColor: 'black !important',
  
  }, 
  '.chakra-ui-dark .basicbox': {
    border: '0px solid !important',
    borderColor: 'highlight !important',
    background: 'white',
  },

  '.smartorderrouter': { 
    background: 'cardgradient.500',
    borderRadius: '28px !important',
    maxWidth: '100vw',
    boxShadow: '0 0 24px var(--chakra-colors-highlight) !important', 
  }, 

  '.chakra-ui-light .smartorderrouter': {
    border: '2px solid',
    borderColor: 'black !important',
  }, 
  '.chakra-ui-dark .smartorderrouter': {
    border: '0px solid !important',
    borderColor: 'highlight !important',
    background: 'white',
  },
  /////////////




    


  '.whiteteal': {
    boxShadow: '0 0 96px -48px var(--chakra-colors-highlight) !important', 
    borderRadius: '24px', 
    maxWidth: '100vw', 
    position: 'relative',
    padding: '1.5px',
    overflow: 'hidden', 
  }, 

  '.chakra-ui-light .whiteteal': {
    border: '1px solid', 
    background: 'white',
  },
  '.chakra-ui-dark .whiteteal': {
    background: 'black',
    border: '0px solid',
    borderColor: 'var(--white) !important',
    // this usage of var works 
    backgroundClip: 'content-box, border-box !important',
    backgroundOrigin: 'border-box !important',
    backgroundImage:
      'linear-gradient(black, black), conic-gradient(var(--chakra-colors-highlight), white, var(--chakra-colors-highlight)) !important',
    overflow: 'hidden',
  },




  '.chakra-ui-dark .tokeninput': {
    border: '1px solid var(--chakra-colors-darkborder) !important',
    borderTop: '0px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '14px !important',
    backgroundColor: 'var(--chakra-colors-outsetComponent)',
    color: 'var(--chakra-colors-text-100) !important',
    padding: '8px',
    boxShadow: 'var(--chakra-shadows-insetComponentShadow)'
  },

  '.chakra-ui-light .tokeninput': {
    border: '0px solid var(--chakra-colors-tableborder) !important',
    borderTop: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '14px !important',
    backgroundColor: 'var(--chakra-colors-insetComponent)',
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.02), rgba(0,0,0,0.02))',
    color: 'rgba(0, 0, 0, 0.8) !important',
    padding: '8px',
    boxShadow: 'var(--chakra-shadows-insetComponent2)'
  },



  '.tabindicator': {
    bg: 'text.100',
    height: '2px',
  },

  '.bg': {
    background:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0.01), rgba(255, 255, 255, 0.01)), linear-gradient(to right, rgba(255, 255, 255, 0.01), rgba(255, 255, 25, 0.01))',
  },

  '.chaindropdown': {
    ...globalDropdownStyles,
    minWidth: '250px !important',
  },

  '.chain': {
    ...globalMenuItemStyles,
    fontSize: '18px',
    height: '50px',
  },
};
