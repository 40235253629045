'use client';

import { useEffectOnce } from '~/lib/util/custom-hooks';
import { Box } from '@chakra-ui/react';
import { useGetAppGlobalPollingDataQuery } from '~/apollo/generated/graphql-codegen-generated';
export function GlobalRenderer() {
  const {
    startPolling: startPollingAppData
  } = useGetAppGlobalPollingDataQuery({
    fetchPolicy: 'network-only'
  });
  useEffectOnce(() => {
    startPollingAppData(30_000);
  });
  return <Box data-sentry-element="Box" data-sentry-component="GlobalRenderer" data-sentry-source-file="GlobalRenderer.tsx">{null}</Box>;
}