import { createAction } from '@reduxjs/toolkit';

export enum MintField {
  CURRENCY_A = 'CURRENCY_A',
  CURRENCY_B = 'CURRENCY_B',
}

export const typeInput = createAction<{
  field: MintField;
  typedValue: string;
  noLiquidity: boolean;
}>('mint/typeInputMint');
export const resetMintState = createAction<void>('mint/resetMintState');
