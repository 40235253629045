export const modeModal = {
  parts: ['overlay', 'dialog', 'content'],
  baseStyle: {
    overlay: {
      bg: 'darkgradient.700',
    },
    dialog: {
      background: 'black',
      backgroundClip: 'content-box, border-box',
      backgroundOrigin: 'border-box',
      backgroundImage:
        'linear-gradient(black, black), conic-gradient(#fff, #c1de08, #c1de08, #c1de08, #c1de08, #fff)',
      borderColor: 'border',
      borderWidth: '0px',
      borderRadius: '24px',
      paddingX: '1px',
      paddingY: '1px',
    },
  },
};