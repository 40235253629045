'use client';

import numeral from 'numeral';
import { useGetProtocolDataQuery } from '~/apollo/generated/graphql-codegen-generated';
import { SubNavBarStat } from './ProtocolStats/SubNavBarStat';
import { useChainId } from 'wagmi';
import { ProtocolVolume } from './ProtocolStats/ProtocolVolume';
import { ProtocolTVL } from './ProtocolStats/ProtocolTVL';
import { StatsPopover } from './ProtocolStats/StatsPopover';
import { SubNavTicker } from './Ticker';
export function SubNavContent() {
  const chainId = useChainId();
  const {
    data,
    loading
  } = useGetProtocolDataQuery({
    variables: {
      chainId
    },
    fetchPolicy: 'cache-first'
  });
  const protocolData = data?.protocolData;
  return <>
      <SubNavBarStat loading={loading && !protocolData} value={numeral(protocolData?.totalLiquidity).format('$0.0a').toUpperCase() || '0'} label="TVL" extra={<StatsPopover>
            <ProtocolTVL />
          </StatsPopover>} data-sentry-element="SubNavBarStat" data-sentry-source-file="SubNavContent.tsx" />

      <SubNavBarStat loading={loading && !protocolData} value={numeral(protocolData?.swapVolume24h).format('$0.0a').toUpperCase() || '0'} label="Vol (24h)" extra={<StatsPopover>
            <ProtocolVolume />
          </StatsPopover>} data-sentry-element="SubNavBarStat" data-sentry-source-file="SubNavContent.tsx" />
      <SubNavTicker data-sentry-element="SubNavTicker" data-sentry-source-file="SubNavContent.tsx" />
    </>;
}