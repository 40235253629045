export const universalDark = {

darkborder: '#333', 

alphaGradient: {
  100: 'linear-gradient(135deg, #1A1E23, #14181E)',
  200: 'linear-gradient(135deg, #1E2228, #181C21)',
}, 

popoverButtonText: '#d4d4d4', 
tableborder: '#535353', 
tablebg: 'transparent', 
layoutbg: 'transparent', 
// the light mode value for layoutbg is set chain to chain, but its universally transparent on dark. 



toggleGradient: { 
  100: 'linear-gradient(135deg, rgba(25, 25, 25,0.9), rgba(25, 25, 25,0.7))',
  200: 'linear-gradient(135deg, rgba(40, 40, 40, 0.8), rgba(45, 45, 45, 1))',
  300: 'linear-gradient(135deg, rgba(25, 25, 25, 0.2), rgba(25, 25, 25,0.1))', 
  400: 'linear-gradient(135deg, rgba(40, 40, 40, 0.5), rgba(25, 25, 25,0.5))', 

}, 


text: {
  100: '#fff',
  200: '#e7e7e7',
  300: '#d4d4d4',
  400: '#BBB',
  450: '#9A9AA6',
  500: '#7f7f7f',
  600: '#656C85',
  650: '#5e5e5e',
  700: '#333',
  750: '#191919',
  800: '#020202',
  900: '#000',
},
textAlpha: {
  100: 'rgba(255, 255, 255, 0.1)',
  200: 'rgba(255, 255, 255, 0.2)',
  300: 'rgba(255, 255, 255, 0.3)',
  400: 'rgba(255, 255, 255, 0.4)',
  500: 'rgba(255, 255, 255, 0.5)',
  600: 'rgba(255, 255, 255, 0.6)',
  700: 'rgba(255, 255, 255, 0.7)',
  800: 'rgba(255, 255, 255, 0.8)',
  900: 'rgba(255, 255, 255, 0.9)',
}, 
gray: {
  '50': '#FAFAFA',
  '100': '#E7E7E7',
  '200': '#D3D3D3',
  '300': '#C7C7C7',
  '400': '#BBB',
  '500': '#8D8D8D',
  '550': '#7A7A7A',
  '600': '#666666',
  '700': '#535353',
  '750': '#404040',
  '800': '#2C2C2C',
  '850': '#191919',
  '900': '#070707',
  '950': '#070707',
},
}