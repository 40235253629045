'use client';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Box } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import { txPendingVar } from '~/lib/util/useSubmitTransaction';
import { ConnectedUser } from './ConnectedUser';
import useDomainName from '../../nav/components/Wallet/useDomainName';
import { WrongNetwork } from './WrongNetwork';
import { NavbarConnectButton } from './NavbarConnectButton';
export default function NavbarConnect() {
  const txPending = useReactiveVar(txPendingVar);
  const {
    domainName
  } = useDomainName();
  return <ConnectButton.Custom data-sentry-element="unknown" data-sentry-component="NavbarConnect" data-sentry-source-file="index.tsx">
      {({
      account,
      chain,
      openAccountModal,
      openChainModal,
      openConnectModal,
      mounted
    }) => {
      const ready = mounted;
      const connected = ready && account && chain;
      return <Box {...!ready && {
        'aria-hidden': true,
        style: {
          opacity: 0,
          pointerEvents: 'none',
          userSelect: 'none'
        }
      }}>
            {!connected ? <NavbarConnectButton openConnectModal={openConnectModal} /> : chain.unsupported ? <WrongNetwork openChainModal={openChainModal} /> : <ConnectedUser openAccountModal={openAccountModal} txPending={txPending} domainName={domainName} account={account} />}
          </Box>;
    }}
    </ConnectButton.Custom>;
}