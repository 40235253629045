import { ChakraTheme } from '@chakra-ui/theme';
import { optimismDark } from './colors/optimismDark';
import { optimismLight } from './colors/optimismLight';
import { optimismButtons } from './buttons';
import { optimismShadows } from './shadows';

export const optimismTheme = (colorMode: 'light' | 'dark'): Partial<ChakraTheme> => ({
  styles: {
    global: {
      html: {},
      body: {      
        color: '#ffffff',
        backgroundColor: '#000',
      },
    },
  },
    fonts: {
    heading: `'ChakraPetch', 'Exo 2', Roboto, "Helvetica Neue", Arial, sans-serif`,
    body: `'ChakraPetch', 'Exo 2', Roboto, "Helvetica Neue", Arial, sans-serif`,
  },

  colors: colorMode === 'light' ? optimismLight : optimismDark,
  shadows: colorMode === 'light' ? optimismShadows.light : optimismShadows.dark, 

  components: {
    Button: {
      ...optimismButtons, 
      variants: {
        ...optimismButtons.variants, 
      },
    },
    // Modal: OptimismModal,

  },
}); 