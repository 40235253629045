'use client';

import React from 'react';
import Image from 'next/image';
import { Box, TextProps } from '@chakra-ui/react';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
interface Props extends TextProps {
  fallback?: string;
}
const DexName: React.FC<Props> = ({
  fallback = '',
  ...textProps
}) => {
  const networkConfig = useNetworkConfig();
  return <>{networkConfig?.appName || fallback}</>;
};

// Currently not used anywhere
const DexIcon = ({
  size = 30
}) => {
  const networkConfig = useNetworkConfig();
  if (!networkConfig?.dexIcon) return null;
  return <Box width={`${size}px`} height={`${size}px`} position="relative" data-sentry-element="Box" data-sentry-component="DexIcon" data-sentry-source-file="DexName.tsx">
      <Image src={networkConfig.dexIcon} fill alt="App Icon" sizes="40px" data-sentry-element="Image" data-sentry-source-file="DexName.tsx" />
    </Box>;
};

// if not statically importing the file, which we cannot here, the browser may throw an error on aspect ratio or missing height/width props
// even when those props are being set in the image component, e.g. <Image height={size} width={size} />
// the browser complains that one is being set, but not the other.
// we could likely bypass this by using width and height, here, instead of just size.
// or we can use size as we do above, where we use it to set the size of the box parent component and then set the Image to have the optional prop
// "fill", meaning it will fill the parent container. in order for this to work properly, you must set "position="relative" "  (or absolute, fixed)
// on the parent container, or it will fill the entire parent container, i.e. in the navbar it takes up the whole navbar.
// the sizes prop is added to tell the browser how big to expect the image to be. it can be done w/ a media query, as well.

export { DexName, DexIcon };