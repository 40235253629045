
const universalOptimismShadows = {
  boxShell: '0 0 12px rgba(255, 4, 32, 0.75), 0 -15px 30px -15px rgba(255, 4, 32, 0.6), 0 0 8px rgba(255, 255, 255, 0.3)',
}

export const optimismShadows = {

  light: {
  ...universalOptimismShadows, 
  componentGlow: '0 0 36px rgba(255, 4, 32, 0.25), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05)', 

  mastheadShadow: `
  -1px -1px 1px rgba(255, 255, 255, 0.5),
  1px 1px 1px rgba(0, 0, 0, 0.3),
  1px 1px 2px rgba(255, 100, 100, 0.4),
  -1px 1px 1px rgba(255, 255, 255, 0.7),
  -1px -1px 2px rgba(255, 100, 100, 0.5),
  1px -1px 1px rgba(0, 0, 0, 0.3), 
  0 0 36px rgba(255, 100, 100, 0.25)
`,
},
  
  dark: { 
    ...universalOptimismShadows, 
    // componentGlow: ' 4px 4px 8px rgba(0, 0, 0, 0.3)', 
    componentGlow: '4px 4px 8px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05)', 

    mastheadShadow:'none', 
  }
 }