'use client';

import { useState } from 'react';
import { Text, Button, Input, Flex, Box, useTheme } from '@chakra-ui/react';
import { useUserSlippageTolerance } from 'state/user/hooks';
import TooltipLabel from '../../tooltips/Label';
import { FoldHorizontalIcon } from 'lucide-react';
enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}
function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`); // match escaped "." characters via in a non-capturing group

const Slippage = () => {
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance();
  const theme = useTheme();
  const [slippageInput, setSlippageInput] = useState('');
  const slippageInputIsValid = slippageInput === '' || (userSlippageTolerance / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2);
  let slippageError: SlippageError | undefined;
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput;
  } else if (slippageInputIsValid && userSlippageTolerance < 50) {
    slippageError = SlippageError.RiskyLow;
  } else if (slippageInputIsValid && userSlippageTolerance > 500) {
    slippageError = SlippageError.RiskyHigh;
  } else {
    slippageError = undefined;
  }
  const parseCustomSlippage = (value: string) => {
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      setSlippageInput(value);
      try {
        const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString());
        if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
          setUserSlippageTolerance(valueAsIntFromRoundedFloat);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  return <Flex direction="column" my="3" data-sentry-element="Flex" data-sentry-component="Slippage" data-sentry-source-file="Slippage.tsx">
      <Flex justify="space-between" mb="2" data-sentry-element="Flex" data-sentry-source-file="Slippage.tsx">
        <Flex mr="2" w="40%" data-sentry-element="Flex" data-sentry-source-file="Slippage.tsx">
          <TooltipLabel color={theme.colors.gray[100]} iconSize={14} icon={FoldHorizontalIcon} tooltipText=" Setting slippage high can help transactions go through, but can result in
          inefficient pricing or transaction front-running. Use with caution." label="Slippage" data-sentry-element="TooltipLabel" data-sentry-source-file="Slippage.tsx" />
        </Flex>
        

        <Flex alignItems="center" justify="flex-start" w="60%" ml="1" data-sentry-element="Flex" data-sentry-source-file="Slippage.tsx">
          <Input size="sm" w="100%" variant="filled" inputMode="decimal" pattern="^[0-9]*[.,]?[0-9]{0,2}$" placeholder={(userSlippageTolerance / 100).toFixed(2)} value={slippageInput} onBlur={() => {
          parseCustomSlippage((userSlippageTolerance / 100).toFixed(2));
        }} onChange={event => {
          if (event.currentTarget.validity.valid) {
            parseCustomSlippage(event.target.value.replace(/,/g, '.'));
          }
        }} isInvalid={!slippageInputIsValid} data-sentry-element="Input" data-sentry-source-file="Slippage.tsx" />

          <Text fontSize="14px" color="gray.300" ml="1" data-sentry-element="Text" data-sentry-source-file="Slippage.tsx">
            %
          </Text>
        </Flex>
      </Flex>

      <Flex direction="column" justify="center" align="flex-start" w="100%" mb="1" data-sentry-element="Flex" data-sentry-source-file="Slippage.tsx">
        <Flex justifyContent="space-evenly" width="100%" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="Slippage.tsx">
          <Button w="100%" mx="1" onClick={() => {
          setSlippageInput('');
          setUserSlippageTolerance(10);
        }} variant={userSlippageTolerance === 10 ? 'toggleoff' : 'toggleon'} data-sentry-element="Button" data-sentry-source-file="Slippage.tsx">
            0.1%
          </Button>
          <Button w="100%" mx="1" onClick={() => {
          setSlippageInput('');
          // console.log('is this clicked');
          setUserSlippageTolerance(50);
        }} variant={userSlippageTolerance === 50 ? 'toggleoff' : 'toggleon'} data-sentry-element="Button" data-sentry-source-file="Slippage.tsx">
            0.5%
          </Button>
          <Button w="100%" mx="1" onClick={() => {
          setSlippageInput('');
          setUserSlippageTolerance(100);
        }} variant={userSlippageTolerance === 100 ? 'toggleoff' : 'toggleon'} data-sentry-element="Button" data-sentry-source-file="Slippage.tsx">
            1.0%
          </Button>
        </Flex>
      </Flex>

      {!!slippageError && <Text fontSize="12px" color="red" mt="0" textAlign="left" ml="2">
          {slippageError === SlippageError.InvalidInput ? 'Enter a valid slippage percentage' : slippageError === SlippageError.RiskyLow ? 'Your transaction may fail' : 'Your transaction may be frontrun'}
        </Text>}
    </Flex>;
};
export default Slippage;