export const invisiblegradients = {

 light: {
  invisiblegradient: `
      repeating-conic-gradient(
        from 0deg,
        rgba(229, 45, 39, 0.55) 0deg 2deg, 
        rgba(255, 255, 240, 0.9) 2deg 15deg
      ),
     radial-gradient(
        circle at 50% 50%,
        rgba(51, 170, 255, 0.2) 0%,
        rgba(229, 45, 39, 0.55) 70%,
        rgba(255, 255, 240, 0.9) 70%
      ), 
     #fffff0 `,
  
  invisiblegradienthero: `
      repeating-conic-gradient(
        from 0deg,
        rgba(229, 45, 39, 0.2) 0deg 2deg, 
        rgba(255, 255, 240, 0.01) 2deg 15deg
      ),
      radial-gradient(
        circle at 50% 50%,
        rgba(229, 45, 39, 0.25) 0%,
        rgba(229, 45, 39, 0.1) 20%,
        rgba(255, 255, 240, 0) 40%
      )
     `, 

 }, 

 dark: { 
  invisiblegradienthero: `
  repeating-conic-gradient(
    from 0deg,
    rgba(229, 45, 39, 0.05) 0deg 1deg, 
    rgba(22, 29, 40, 0.05) 1deg 15deg
  )`, 
  invisiblegradient: `
    repeating-conic-gradient(
      from 0deg,
      rgba(229, 45, 39, 0.15) 0deg 2deg, 
      rgba(35, 49, 66, 0.9) 2deg 15deg
    ),
    rgba(35, 49, 66, 1) `,  
   }, 


}
