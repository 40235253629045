import { mode } from '@chakra-ui/theme-tools';
import { globalButtons } from '../../global/buttons/mainbuttons';

export const baseButtons = {
  variants: {
    primary: (props) => ({
      ...globalButtons.variants.primary(props),
      _hover: {
        background:  'linear-gradient(to top, #19B8E8, #00A8E8)',
      },
      _disabled: {
        background: 'linear-gradient(135deg, #A3C4D9, #7AA7C4)',
        color: '#0E1C2A',
      },
    }),

    universalpawg: (props) => ({
      ...globalButtons.variants.universalpawg(props),
      background: mode(
        'linear-gradient(to bottom, #96B7D0, #3A5B7A)',
        'linear-gradient(to bottom, #96B7D0, #3A5B7A)',
      )(props),
      color: '#fff',
    borderWidth: '0px !important',
    borderColor: 'transparent !important',  
      fontWeight: '500',
      letterSpacing: '0px',
      boxShadow: 'primaryButtonShadow',
      _hover: {
        background:  'linear-gradient(to top, #96B7D0, #3A5B7A)',
        boxShadow: mode('insetComponentShadow', ' 0 0 12px #00A8E8')(props),  

      },
      _disabled: {
        background: 'linear-gradient(135deg, #A3C4D9, #7AA7C4)',
        color: '#0E1C2A',
      },
    }),

    cosmicpawg: (props) => ({
      ...globalButtons.variants.cosmicpawg(props),
      background: 'linear-gradient(to bottom right, #19B8E8, #00A8E8)',

      color: mode('#fff', '#fff')(props), 
      fontWeight: '500',
      letterSpacing: '0px',
      boxShadow: 'primaryButtonShadow',
      borderWidth: '0px', 
      borderColor: 'transparent', 

      _hover: {
        background: mode(`linear-gradient(to top left, #19B8E8, #00A8E8)`, 
          `linear-gradient(to top left, #b3e6fa, #00A8E8)`)(props), 
        boxShadow: mode('insetComponentShadow', '0 0 12px #00a8e8')(props), 


      },
      _disabled: {
        background: 'linear-gradient(135deg, #A3C4D9, #7AA7C4)',
        color: '#0E1C2A',
      },
    }),
  },
};
