import { Text, Flex, Divider } from '@chakra-ui/react';
import Gas from './Gas';
import Slippage from './Slippage';
import Deadline from './Deadline';
import ExpertMode from './ExpertMode';
import SwapChartToggle from './SwapChartToggle';
const SettingsLayout = () => {
  return <Flex flexDirection="column" mb="0px" px="1" data-sentry-element="Flex" data-sentry-component="SettingsLayout" data-sentry-source-file="SettingsLayout.tsx">
      <Gas data-sentry-element="Gas" data-sentry-source-file="SettingsLayout.tsx" />
      <Divider data-sentry-element="Divider" data-sentry-source-file="SettingsLayout.tsx" />
      <Slippage data-sentry-element="Slippage" data-sentry-source-file="SettingsLayout.tsx" />
      <Divider data-sentry-element="Divider" data-sentry-source-file="SettingsLayout.tsx" />

      <Deadline data-sentry-element="Deadline" data-sentry-source-file="SettingsLayout.tsx" />
      <Divider data-sentry-element="Divider" data-sentry-source-file="SettingsLayout.tsx" />
      <ExpertMode data-sentry-element="ExpertMode" data-sentry-source-file="SettingsLayout.tsx" />
      <Divider data-sentry-element="Divider" data-sentry-source-file="SettingsLayout.tsx" />
      <SwapChartToggle data-sentry-element="SwapChartToggle" data-sentry-source-file="SettingsLayout.tsx" />
    </Flex>;
};
export default SettingsLayout;