import { mode } from '@chakra-ui/theme-tools';
import { globalButtons } from '../../global/buttons/mainbuttons';
// bg: mode('porcelain', 'black')(props),

export const baseButtons = {
  variants: {
    universalpawg: (props) => ({
      ...globalButtons.variants.universalpawg(props), 
      background: mode('linear-gradient(90deg, #2D7FDD 0%, #4C6FE5 100%)', 'linear-gradient(135deg, rgba(1, 150, 254, 1), rgba(55, 192, 223, 1))')(props), 
      color: mode('white', 'text.100')(props), 
      borderWidth: mode('0px', '1px')(props), 
      borderColor: mode('#0066cc', 'rgba(255, 255, 255, 0.75)')(props), 
      fontWeight: '400',
      letterSpacing: '0px',
      boxShadow: 'lifted',
      _hover: {
        background: mode('linear-gradient(180deg, #2D7FDD 0%, #4C6FE5 100%)',  'linear-gradient(270deg, rgba(1, 84, 254, 1)  0%, rgba(55, 192, 223, 1) 100%)')(props), 
        borderWidth: mode('0px', '1px')(props), 

      },
      _disabled: {
        background: 'linear-gradient(135deg, #f0f0f0, #004080)', // this would be good on full opac
        color: '#555', 
      }
    }), 


    cosmicpawg: (props) => ({
      ...globalButtons.variants.cosmicpawg(props), 
      background: mode('linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%)', 'rgba(1,84,254,1)')(props), 
      color: mode('#000', '#fff')(props),
      borderWidth: mode('0px', '1px')(props), 
      borderColor: 'rgba(255, 255, 255, 0.1)', 
      fontWeight: '400', 
      letterSpacing: '0px',
      boxShadow: mode('lifted', 'inset 0 1px 2px rgba(0, 0, 0, 0.5)')(props), 
      _hover: {
        boxShadow: 'insetComponentShadow', 

      },
      _disabled: {
        background: 'linear-gradient(135deg, #f0f0f0, #004080)', 
        color: '#555', 
      }
    }),
  }
};
