import React from 'react';
import { Button, HStack, Spinner, Text, useTheme } from '@chakra-ui/react';
import { IconWallet } from '~/app/components/icons/Misc/IconWallet';
import { Wallet } from 'lucide-react';
import { NAVBAR_ITEMS_HEIGHT } from './NavbarConnectButton';
interface ConnectedUserProps {
  openAccountModal: () => void;
  txPending: boolean;
  domainName?: string;
  account?: {
    displayName: string;
  };
}
export const ConnectedUser: React.FC<ConnectedUserProps> = ({
  openAccountModal,
  txPending,
  domainName,
  account
}) => {
  const theme = useTheme();
  return <HStack spacing="0" position="relative" data-sentry-element="HStack" data-sentry-component="ConnectedUser" data-sentry-source-file="ConnectedUser.tsx">
      <Button variant="toggleon" height={NAVBAR_ITEMS_HEIGHT} onClick={openAccountModal} position="relative" aria-label="Open Account Modal" data-sentry-element="Button" data-sentry-source-file="ConnectedUser.tsx">
        <HStack justifyContent="center" alignItems="center" gap="3" width="full" data-sentry-element="HStack" data-sentry-source-file="ConnectedUser.tsx">
          {txPending ? <Spinner color="text.100" /> : <Wallet size="15px" color={theme.colors.text[100]} />}
          <Text display="flex" fontSize={{
          base: '12px',
          xl: '15px'
        }} color="text.100" fontWeight="400" letterSpacing="-0.5px" data-sentry-element="Text" data-sentry-source-file="ConnectedUser.tsx">
            {domainName ? domainName : account?.displayName}
          </Text>
        </HStack>
      </Button>
    </HStack>;
};