import { mode } from '@chakra-ui/theme-tools';

export const Menu = {
  variants: {
    pawg: (props) => ({
      list: {
        bg: 'transparent',
        borderRadius: '12px',
        px: '1',
        boxShadow: 'popover.dropShadow',
        minW: '200px',
        py: '4px',
      },
      item: {
        bg: 'transparent',
        border: '0px',
        borderRadius: '12px',
        color: 'text.100',
        fontSize: '14px',
        fontWeight: '500',
        px: '12px',
        py: '4px',
        _hover: {
          borderWidth: '0px !important',
          boxShadow: 'none !important',
          outline: 'none !important',
        },
        _before: {
          borderWidth: '0px',
          boxShadow: 'none',
        },
        _after: {
          borderWidth: '0px',
          boxShadow: 'none',
        },
        // transition: 'all 0.2s',

        _focus: {},
        _active: {},
      },
      groupTitle: {},
      command: {},
      divider: {},
    }),
  },
  defaultProps: {},
};
