import { invisiblegradients } from './invisiblegradients';
import { optimismGradients } from './optimismGradients';

// primary, highlight, border, border2, navbarbg, navbarborder, outsetcomponent, insetcomponent,
// tablebg, primarybutton, tokeninput, uibackground, mastheadtextgradient, settingstextgradient, dextone

export const optimismDark = {
  ...optimismGradients,
  ...invisiblegradients.dark,
  primary: '#ff0420',
  highlight: '#FF6666',
  navbarselected: '#ff0420',
  tabselected: '#fff',
  createposition: '#fff',

  tokeninputswap: '#fff',

  border: '#a1a1a1',
  border2: 'rgba(51,51,51,0.2)',

  navbarbg: 'linear-gradient(135deg, #111, #161D28)',
  subnav: 'linear-gradient(135deg, #111, #161D28)',

  navbarborder: '#a1a1a1',

  outsetComponent: 'linear-gradient(135deg, #111, #161D28)',
  insetComponent: 'linear-gradient(135deg, #233142, #111)',
  dashboardshell: 'linear-gradient(135deg, #111, #161D28)',
  insetComponentDropdown: 'linear-gradient(135deg, #233142, #111)',

  tablebg:
    'linear-gradient(135deg, rgba(17, 17, 17, 0.5), rgba(35, 49, 66, 0.5), rgba(17, 17, 17, 0.25), rgba(35, 49, 66, 0.5))',
  primarybutton: 'linear-gradient(135deg, #ff3366 0%, #ff5555 50%, #ff7777 100%)',
  swaptokeninput: 'linear-gradient(135deg, #111, #161D28)',

  uibackground: `
  radial-gradient(at 12.5% 81.24213506711409%, #1D2630 0px, transparent 50%), 
  radial-gradient(at 75% 98%, #2B4162 0px, transparent 50%), 
  radial-gradient(at 19.20731707317073% 18.32267197986577%, #1D2630 0px, transparent 50%), 
  radial-gradient(at 84.04471544715447% 19.329383389261746%, #1D2630 0px, transparent 50%)
`,
  // uibackground: 'linear-gradient(135deg, #31373F, #233142)',
  mastheadtextgradient: `linear-gradient( 135deg, #ff0420 0%, #ff3b4e 25%, #ff7075 50%, #ffa59d 75%, #ffd6d3 100% )`,
  settingstextgradient: `linear-gradient( 135deg, #ff0420 0%, #ff0420 25%, #ff0420 50%, #ffa59d 75%, #fff 100% )`,

  dextone: {
    100: '#171717',
    200: '#0F0F0F',
    300: '#0f1420',
    400: '#0c0f19',
    500: '#090b16',
    600: '#080a14',
    700: '#070912',
    800: '#06080f',
    900: '#05070d',
  },

  insetComponentrgba: 'rgba(35, 49, 66, 1)', // #233142
  outsetComponentrgba: 'rgba(22, 29, 40, 1)', // #161d28
  darkinsetrgba: 'rgba(17, 17, 17, 1)', // #111
};
