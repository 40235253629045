export const soneiumGradients = {
  dexgradient: {
    100: 'linear-gradient(to right, #96B7D0, #3A5B7A)',
    200: 'linear-gradient(to bottom, #96B7D0, #3A5B7A)',
    300: 'linear-gradient(to right, #3A5B7A, #B0B0B0)',
    400: 'linear-gradient(to right, #96B7D0, #3A5B7A 20%, #D0D5D9)',
    500: 'linear-gradient(to right, rgba(58, 91, 122, 1), #1C3346)',
    600: 'linear-gradient(to left, rgba(58, 91, 122, 1), #1C3346)',
  },

  invisiblegradient: `
repeating-conic-gradient(
  from 0deg,
  rgba(58, 91, 122, 0.5) 0deg 2deg, 
  rgba(219, 223, 230, 0.9) 2deg 15deg
),
radial-gradient(
  circle at 50% 50%,
  rgba(150, 183, 208, 0.2) 0%,
  rgba(58, 91, 122, 0.5) 70%,
  rgba(182, 182, 182, 0.9) 70%
),
#D0D5D9
`,

  primarybutton: 'linear-gradient(to right, #96B7D0, #3A5B7A)',
  buttongroup: 'linear-gradient(to bottom, #96B7D0, #3A5B7A)',
  scrollbar: 'linear-gradient(to bottom, #3A5B7A, #B0B0B0)',
  slidertrack: 'linear-gradient(to left, #3A5B7A, #B0B0B0)',

  darkgradient: {
    100: 'linear-gradient(to bottom, #1C3346, #3A5B7A, #1C3346)',
    200: 'linear-gradient(to right, #1C3346, rgba(58, 91, 122, 0.5), #1C3346)',
    300: 'linear-gradient(to bottom, #1C3346, rgba(58, 91, 122, 0.75), #1C3346)',
    400: 'linear-gradient(to bottom, #3A5B7A, #5B7C9A, #3A5B7A)',
    500: 'linear-gradient(to top, #1C3346, #3A5B7A)',
    600: 'linear-gradient(to top, #5B7C9A, rgba(150, 183, 208, 0.1))',
    700: 'linear-gradient(to bottom, rgba(58, 91, 122, 0.85), rgba(28, 51, 70, 0.5))',
    800: 'linear-gradient(to right, #1C3346, #3A5B7A)',
    900: 'linear-gradient(to bottom, #0A1A28, #3A5B7A, #0A1A28)',
  },

  cardgradient: {
    100: 'radial-gradient(rgba(58, 91, 122, 0.75), #1C3346)',
    200: 'linear-gradient(to bottom, #1C3346, #5B7C9A, #1C3346)',
    300: 'radial-gradient(#1C3346, rgba(58, 91, 122, 0.75))',
    400: 'radial-gradient(at 10% top, rgba(58,91,122,0.75) 0%, transparent 25%), radial-gradient(ellipse at center top, rgba(58,91,122,0.75) 0%, transparent 30%), radial-gradient(at right 10%, rgba(58,91,122,0.75) 0%, transparent 20%), radial-gradient(at left center, rgba(58,91,122,0.75) 0%, transparent 30%), radial-gradient(ellipse at center center, rgba(150,183,208,0.5) 0%, transparent 30%), radial-gradient(at 100% 40%, rgba(182,182,182,0.6) 0%, transparent 25%), radial-gradient(at left bottom, rgba(58,91,122,0.75) 0%, transparent 35%), radial-gradient(at center 80%, rgba(182,182,182,0.65) 0%, transparent 25%), radial-gradient(at right bottom, rgba(58,91,122,0.75) 0%, transparent 35%)',
    500: 'radial-gradient(at 0% 50%, rgba(58,91,122,0.75) 0%, transparent 50%), radial-gradient(at 100% 50%, rgba(150,183,208,0.75) 0%, transparent 58%), radial-gradient(at 50% 0%, rgba(182,182,182,0.75) 0%, transparent 50%),  radial-gradient(at 50% 100%, rgba(182,182,182,0.75) 0%, transparent 58%)',
    600: 'linear-gradient(to right, rgba(58, 91, 122, 0.25), #1C3346)',
    700: 'none',
  },

  baseswap: {
    50: '#D6E3E8',
    100: '#B2C9D1',
    200: '#8EAFBA',
    300: '#6A95A3',
    400: '#46798C',
    500: '#3A5B7A',
    600: '#2A4459',
    700: '#1A2D38',
    800: '#0A1617',
    900: '#000506',
  },
};
