import Link from 'next/link';
import { ChainId } from '@baseswapfi/sdk-core';
interface WhatsNewUpdate {
  id: number;
  date: string;
  message: React.ReactNode;
  chains?: ChainId[];
}
export const getUpdatesForChain = (chainId: ChainId) => {
  return updates.filter(update => !update.chains || update.chains.includes(chainId));
};
export const updates: WhatsNewUpdate[] = [{
  id: 5,
  date: '2024-12-04',
  message: <>
        New listing frenzy! AIXBT, DONNIE, FUEGO and SKI now live in{' '}
        <Link href="/swap" style={{
      textDecoration: 'underline'
    }}>
          Swap
        </Link>
        !
      </>,
  chains: [ChainId.BASE]
}, {
  id: 4,
  date: '2024-12-03',
  message: <>
        New listing alert! Join the Virtuals craze on BaseSwap!{' '}
        <Link href="/swap" style={{
      textDecoration: 'underline'
    }}>
          Trade
        </Link>{' '}
        Chaos, Clunker, Joy, Music, Pondhub and Simmi!
      </>,
  chains: [ChainId.BASE]
}, {
  id: 3,
  date: '2024-12-02',
  message: <>
        Introducing new fee tiers! We now support V3 positions with 0.005%, 0.20%, 0.85% and 2%! Get
        started&nbsp;
        <Link href="/addV3" style={{
      textDecoration: 'underline'
    }}>
          here
        </Link>
        !
      </>,
  chains: [ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE]
}, {
  id: 2,
  date: '2024-11-28',
  message: `Happy Thanksgiving to everyone celebrating in the U.S.! Wherever you are, we're grateful for you and glad you're a part of this amazing DeFi community! 🦃`
}, {
  id: 1,
  date: '2024-11-27',
  message: `Wondering what's new? Check back here for the latest updates!`
}];