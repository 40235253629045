import { soneiumGradients } from './soneiumGradients';
import { invisiblegradients } from './invisiblegradients';

// primary, highlight, border, border2, navbarbg, navbarborder, outsetcomponent, insetcomponent,
// tablebg, primarybutton, tokeninput, uibackground, mastheadtextgradient, settingstextgradient, dextone
// should copy the flow of the button approval process from the soneium bridge
export const soneiumDark = {
  ...soneiumGradients,
  ...invisiblegradients.dark,
  primary: '#B3F4FD',
  highlight: '#C7D9E8',
  navbarselected: '#B3F4FD',
  tabselected: '#fff',
  createposition: '#fff',

  border: '#C7D9E8',
  border2: 'rgba(199, 217, 232, 0.5)',

  navbarbg: '#000',
  subnav: '#000',
  navbarborder: '#C6CC8A',

  outsetComponent: 'radial-gradient(#000, #000, #000)',
  insetComponent: `linear-gradient(to right, #000, #111, #000)`,
  insetComponentDropdown: `linear-gradient(135deg, #0C1721, #152433)`,
  uibackground: `
    radial-gradient(at 0% 0%, rgba(10, 20, 40, 0.8) 0%, transparent 50%),
    radial-gradient(ellipse at 100% 10%, rgba(0, 255, 255, 0.05) 0%, transparent 20%),
    radial-gradient(at 20% 20%, rgba(255, 223, 0, 0.12) 0%, transparent 40%),
    radial-gradient(at 80% 80%, rgba(255, 0, 255, 0.08) 0%, transparent 35%),
    radial-gradient(at 10% 90%, rgba(0, 255, 255, 0.15) 0%, transparent 30%),
    radial-gradient(at 90% 10%, rgba(255, 192, 203, 0.1) 0%, transparent 35%),
    radial-gradient(at 100% 100%, rgba(10, 20, 40, 0.8) 0%, transparent 50%),
    #000000;`,

  dashboardshell: 'transparent',
  tablebg:
    'linear-gradient(135deg, rgba(21, 36, 51, 0.5), rgba(0, 150, 208, 0.5), rgba(199, 217, 232, 0.25), rgba(255, 140, 0, 0.1))',

  primarybutton: 'radial-gradient(#000, #000)',

  tokeninput: 'rgba(199, 217, 232, 0.25)',

  mastheadtextgradient: `linear-gradient(
    135deg,
    #B3F4FD 0%,
    #c0c0c0 25%, 
    #EDB4F2 50%,
    #B3F4FD 75%,
    #FFFFFF 100%
  )`,

  settingstextgradient: `linear-gradient(
     135deg,
     #0096D0 0%,
     #4AA3D2 25%,
     #87CEEB 50%,
     #C7D9E8 75%,
     #FFFFFF 100%
   )`,

  dextone: {
    100: '#C7D9E8',
    200: '#96B7D0',
    300: '#6595B8',
    400: '#FFFFFF',
    500: '#035388',
    600: '#023D66',
    700: '#012744',
    800: '#001122',
    900: '#000B10',
  },
};

// Updated RGBA values
// primaryrgba: 'rgba(0, 150, 208, 1)',
// highlightrgba: 'rgba(199, 217, 232, 0.25)',
// tealrgba: 'rgba(0, 150, 208, 1)',
// orangergba: 'rgba(255, 140, 0, 0.1)'
