import { ThemeConfig } from '@chakra-ui/react';
import { arbitrumTheme } from './arbitrum/arbitrumTheme';
import { baseTheme } from './base/baseTheme';
import { modeTheme } from './mode/modeTheme';
import { optimismTheme } from './optimism/optimismTheme';
import { soneiumTheme } from './soneium/soneiumTheme';

export const themesByChainId = {
  '8453': baseTheme,
  '34443': modeTheme,
  '10': optimismTheme,
  '42161': arbitrumTheme,
  '1946': soneiumTheme, 
};

export const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};
