'use client';

import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useNavVisibility } from './NavbarContent/NavVisibilityContext';
const MotionBox = props => <Box as={motion.div} {...props} data-sentry-element="Box" data-sentry-component="MotionBox" data-sentry-source-file="Navbar.tsx" />;
const MotionVStack = props => <VStack as={motion.div} {...props} data-sentry-element="VStack" data-sentry-component="MotionVStack" data-sentry-source-file="Navbar.tsx" />;
interface NavbarProps {
  children: React.ReactNode;
}
const Navbar: React.FC<NavbarProps> = ({
  children,
  ...props
}) => {
  const {
    isVisible
  } = useNavVisibility();
  return <MotionBox position="fixed" top={isVisible ? '0' : '-100px'} opacity={isVisible ? 1 : 0} transition={{
    ease: 'easeInOut',
    duration: 1
  }} width="100%" zIndex="20" {...props} data-sentry-element="MotionBox" data-sentry-component="Navbar" data-sentry-source-file="Navbar.tsx">
      <MotionVStack w="full" spacing="0" align="stretch" initial={{
      opacity: 0
    }} animate={{
      opacity: isVisible ? 1 : 0
    }} transition={{
      ease: 'easeInOut',
      duration: 1
    }} data-sentry-element="MotionVStack" data-sentry-source-file="Navbar.tsx">
        <Box data-sentry-element="Box" data-sentry-source-file="Navbar.tsx">{children}</Box>
      </MotionVStack>
    </MotionBox>;
};
export default Navbar;