'use client';

import React, { useMemo } from 'react';
import { useTheme as useNextTheme } from 'next-themes'; // from next-themes
import { useTheme as useChakraTheme } from '@chakra-ui/react'; // from Chakra UI
import { RainbowKitProvider, darkTheme, lightTheme } from '@rainbow-me/rainbowkit';
import { WalletUserAvatar } from '../components/avatar/WalletUserAvatar';
import { CHAIN_MAIN_NAME } from '~/constants';
const RainbowKitWithTheme = ({
  children
}) => {
  const {
    resolvedTheme
  } = useNextTheme();
  const chakraTheme = useChakraTheme();
  const highlightColor = chakraTheme?.colors?.highlight || '#3af';
  const rainbowTheme = useMemo(() => {
    return resolvedTheme === 'dark' ? darkTheme({
      borderRadius: 'medium',
      fontStack: 'system',
      accentColor: highlightColor
    }) : lightTheme({
      borderRadius: 'medium',
      fontStack: 'system',
      accentColor: highlightColor
    });
  }, [resolvedTheme]);
  return <RainbowKitProvider coolMode showRecentTransactions={true} appInfo={{
    appName: CHAIN_MAIN_NAME,
    learnMoreUrl: 'https://medium.com/blockchain/crypto-wallets-explained-f9199e621366'
  }} theme={rainbowTheme} avatar={() => <WalletUserAvatar />} data-sentry-element="RainbowKitProvider" data-sentry-component="RainbowKitWithTheme" data-sentry-source-file="RainbowKitWithTheme.tsx">
      {children}
    </RainbowKitProvider>;
};
export default RainbowKitWithTheme;