const universalSoneiumShadows = {
  boxShell: '0 -15px 30px -15px rgba(26, 42, 58, 0.75), 0 0 12px #000',
};

//#DB87E6
export const soneiumShadows = {
  light: {
    ...universalSoneiumShadows,

    componentGlow:
      '0 0 36px rgba(0, 168, 232, 0.25), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05)',

    mastheadShadow: `
  -1px -1px 1px rgba(255, 255, 255, 0.8),
  1px 1px 1px rgba(0, 0, 0, 0.3),
  1px 1px 2px rgba(0, 168, 232, 0.4),
  -1px 1px 1px rgba(255, 255, 255, 0.7),
  -1px -1px 2px rgba(217, 229, 242, 0.4),
  1px -1px 1px rgba(0, 0, 0, 0.3), 
  0 0 36px rgba(0, 168, 232, 0.25)
 `,
  },

  dark: {
    ...universalSoneiumShadows,
    componentGlow:
      '0 0 36px rgba(0, 168, 232, 0.25), 20px 20px 50px 0px rgba(0, 0, 0, 0.25), 10px 10px 25px 0px rgba(0, 0, 0, 0.18), 2px 2px 11px 0px rgba(0, 0, 0, 0.19), 0px -1px 1px 0px rgba(217, 229, 242, 0.25)',
    mastheadShadow: `
  -1px -1px 1px rgba(0, 168, 232, 0.2),
  1px 1px 1px rgba(0, 0, 0, 0.3),
  1px 1px 2px rgba(217, 229, 242, 0.4),
  -1px 1px 1px rgba(0, 168, 232, 0.2),
  -1px -1px 2px rgba(255, 255, 255, 0.4),
  1px -1px 1px rgba(0, 0, 0, 0.3), 
  0 0 36px rgba(0, 168, 232, 0.25)
 `,
  },
};
