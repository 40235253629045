import { ChakraTheme } from '@chakra-ui/theme';
import { baseDark } from './colors/baseDark';
import { baseLight } from './colors/baseLight';
import { baseTextStyles } from './textStyles';
import { baseButtons } from './buttons';
import { baseShadows } from './shadows';
export const baseTheme = (colorMode: 'light' | 'dark'): Partial<ChakraTheme> => ({
  styles: {
    global: {
      html: {},
      body: {      
        color: '#ffffff',
        backgroundColor: '#000',
      },
    },
  },  fonts: {
  },
  colors: colorMode === 'light' ? baseLight : baseDark,
  shadows: colorMode === 'light' ? baseShadows.light : baseShadows.dark, 
  textStyles: baseTextStyles,
  components: {
    Button: {
      ...baseButtons, 
      variants: {
        ...baseButtons.variants, 
      },
    },
  },
})
