import Image from 'next/image';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
import { networkList } from '~/lib/config/network-config';
import { Box, Flex } from '@chakra-ui/react';
export function WalletUserAvatar() {
  const {
    chainId
  } = useNetworkConfig();
  const networkConfig = useNetworkConfig();
  const currentNetwork = networkList.find(network => network.chainId === chainId);
  return <Flex direction="column" data-sentry-element="Flex" data-sentry-component="WalletUserAvatar" data-sentry-source-file="WalletUserAvatar.tsx">
      <Box width="50px" height="50px" position="relative" data-sentry-element="Box" data-sentry-source-file="WalletUserAvatar.tsx">
        <Image src={networkConfig.dexIconInRepo} fill alt="Your profile" sizes="80px" data-sentry-element="Image" data-sentry-source-file="WalletUserAvatar.tsx" />
      </Box>
      {/* <Image src={networkConfig.dexIcon} width={50} height={50} alt="Your profile" /> */}
      {/* <Image src={currentNetwork.iconUrl} width={20} height={20} alt="Your profile" /> */}
    </Flex>;
}