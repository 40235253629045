'use client';

import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useNavVisibility } from '../NavVisibilityContext';
import { SubNavContent } from './SubNavContent';
const MotionBox = props => <Box as={motion.div} {...props} data-sentry-element="Box" data-sentry-component="MotionBox" data-sentry-source-file="index.tsx" />;
export function SubNavBar() {
  const {
    isVisible
  } = useNavVisibility();
  return <MotionBox mt="0px" px={{
    base: '1',
    lg: '8'
  }} opacity={isVisible ? 1 : 0} top={isVisible ? '0' : '-50px'} transition={{
    ease: 'easeInOut',
    duration: 0.5
  }} position="relative" width="fit-content" bg="subnav" borderBottomRightRadius="12px" borderBottomWidth="1px" borderRightWidth="1px" borderBottomColor="darkborder" borderRightColor="darkborder" data-sentry-element="MotionBox" data-sentry-component="SubNavBar" data-sentry-source-file="index.tsx">
      <HStack data-sentry-element="HStack" data-sentry-source-file="index.tsx">
        <Box px={{
        base: '3',
        lg: '4'
      }} py={{
        base: '1.5',
        lg: '1.5'
      }} height="30px" display="flex" data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <SubNavContent data-sentry-element="SubNavContent" data-sentry-source-file="index.tsx" />
        </Box>
      </HStack>
    </MotionBox>;
}