import { mode } from '@chakra-ui/theme-tools';

export const customTabs = {
  variants: {
    inset: (props) => ({
      tablist: {
        bg: 'insetComponent',
        borderRadius: '8px',
        padding: '1',
        boxShadow: 'insetComponentShadow',
        width: 'auto',
      },
      tab: {
        borderRadius: '8px',
        background: 'transparent',
        fontWeight: '500',
        color: mode('text.100', 'gray.400')(props),
        _selected: {
          background: 'buttongroup',
          color: mode('tabselected', 'text.900')(props),
          boxShadow: 'lifted',
          _hover: {
            color: mode('white', 'white')(props),
          },
        },
        _hover: {
          color: mode('gray.600', 'white')(props),
        },
        transition: 'all 0.2s',
      },
    }),
    inset2: (props) => ({
      tablist: {
        bg: 'insetComponent',
        borderRadius: '8px',
        paddingX: '8px',
        paddingY: '4px',

        boxShadow: 'insetComponentShadow',
        width: 'auto',
      },
      tab: {
        borderRadius: '8px',
        background: 'transparent',
        fontWeight: '500',
        color: mode('text.100', 'gray.400')(props),
        _selected: {
          background: 'buttongroup',
          color: mode('tabselected', 'white')(props),
          boxShadow: 'lifted',
          _hover: {
            color: mode('tabselected', 'white')(props),
          },
        },
        _hover: {
          color: mode('gray.600', 'white')(props),
        },
        transition: 'all 0.2s',
      },
    }),
  },
};
