import { DependencyList, useEffect, useRef } from 'react';

export function useEffectOnce(effect: () => void) {
  useEffect(effect, []);
}

export function useAsyncEffect(
  effect: () => Promise<void>,
  deps?: DependencyList,
  cleanup?: () => void,
) {
  useEffect(() => {
    effect();

    return cleanup;
  }, deps);
}

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
