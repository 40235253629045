'use client';

import { Text, Flex, Divider } from '@chakra-ui/react';
import { useGetProtocolDataQuery } from '~/apollo/generated/graphql-codegen-generated';
import { useChainId } from 'wagmi';
export function ProtocolTVL() {
  const chainId = useChainId();
  const {
    data
  } = useGetProtocolDataQuery({
    variables: {
      chainId
    },
    fetchPolicy: 'cache-first'
  });
  const protocolData = data?.protocolData;
  return <>
      <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-source-file="ProtocolTVL.tsx">
        <Text color="gray.300" fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx">
          V2
        </Text>
        <Text color="gray.300" fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx">
          {protocolData?.totalLiquidityV2}
        </Text>
      </Flex>

      <Divider pt="2" data-sentry-element="Divider" data-sentry-source-file="ProtocolTVL.tsx" />

      <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-source-file="ProtocolTVL.tsx">
        <Text color="gray.300" fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx">
          V3
        </Text>
        <Text color="gray.300" fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx">
          {protocolData?.totalLiquidityV3}
        </Text>
      </Flex>

      <Divider pt="2" data-sentry-element="Divider" data-sentry-source-file="ProtocolTVL.tsx" />

      <Flex justifyContent="space-between" data-sentry-element="Flex" data-sentry-source-file="ProtocolTVL.tsx">
        <Text color="gray.300" fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx">
          Staking/Boost
        </Text>
        <Text color="gray.300" fontSize={{
        base: 'sm',
        lg: '14px'
      }} data-sentry-element="Text" data-sentry-source-file="ProtocolTVL.tsx">
          {protocolData?.stakersTVL}
        </Text>
      </Flex>
    </>;
}