import { parseUnits } from 'ethers/lib/utils';

export enum GAS_PRICE {
  default = '0.0001',
  fast = '0.1',
  instant = '1',
  testnet = '2',
}

export const GAS_PRICE_GWEI = {
  default: parseUnits(GAS_PRICE.default, 'gwei').toString(),
  fast: parseUnits(GAS_PRICE.fast, 'gwei').toString(),
  instant: parseUnits(GAS_PRICE.instant, 'gwei').toString(),
  testnet: parseUnits(GAS_PRICE.testnet, 'gwei').toString(),
};
