'use client';

import { useState } from 'react';
import { Text, Button, Input, Flex, useTheme } from '@chakra-ui/react';
import { useUserTransactionTTL } from 'state/user/hooks';
import TooltipLabel from '../../tooltips/Label';
import { ChevronsRightLeftIcon } from 'lucide-react';
enum DeadlineError {
  InvalidInput = 'InvalidInput',
}
const THREE_DAYS_IN_SECONDS = 60 * 60 * 24 * 3;
const Deadline = () => {
  const [ttl, setTtl] = useUserTransactionTTL();
  const [deadlineInput, setDeadlineInput] = useState('');
  const deadlineInputIsValid = deadlineInput === '' || (ttl / 60).toString() === deadlineInput;
  let deadlineError: DeadlineError | undefined;
  if (deadlineInput !== '' && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput;
  } else {
    deadlineError = undefined;
  }
  const theme = useTheme();
  const parseCustomDeadline = (value: string) => {
    setDeadlineInput(value);
    try {
      const valueAsInt: number = Number.parseInt(value) * 60;
      if (!Number.isNaN(valueAsInt) && valueAsInt > 60 && valueAsInt < THREE_DAYS_IN_SECONDS) {
        setTtl(valueAsInt);
      } else {
        deadlineError = DeadlineError.InvalidInput;
      }
    } catch (error) {
      console.error(error);
    }
  };
  return <Flex justify="space-between" my="3" data-sentry-element="Flex" data-sentry-component="Deadline" data-sentry-source-file="Deadline.tsx">
      <Flex mr="2" w="40%" data-sentry-element="Flex" data-sentry-source-file="Deadline.tsx">
        <TooltipLabel icon={ChevronsRightLeftIcon} iconSize={14} tooltipText="Your transaction will revert if it is left confirming for longer than this time." label="Deadline" color={theme.colors.gray[100]} data-sentry-element="TooltipLabel" data-sentry-source-file="Deadline.tsx" />
      </Flex>

      <Flex alignItems="center" justify="flex-start" w="60%" ml="1" data-sentry-element="Flex" data-sentry-source-file="Deadline.tsx">
        <Input size="sm" w="100%" variant="filled" inputMode="numeric" pattern="^[0-9]+$" isInvalid={!!deadlineError} onBlur={() => {
        parseCustomDeadline((ttl / 60).toString());
      }} placeholder={(ttl / 60).toString()} value={deadlineInput} onChange={event => {
        if (event.currentTarget.validity.valid) {
          parseCustomDeadline(event.target.value);
        }
      }} data-sentry-element="Input" data-sentry-source-file="Deadline.tsx" />
        <Text fontSize="14px" color="gray.300" ml="1" data-sentry-element="Text" data-sentry-source-file="Deadline.tsx">
          min
        </Text>
      </Flex>
    </Flex>;
};
export default Deadline;