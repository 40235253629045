import { ChakraTheme } from '@chakra-ui/theme';
import { soneiumButtons } from './buttons';
import { soneiumDark } from './colors/soneiumDark';
import { soneiumLight } from './colors/soneiumLight';
import {soneiumShadows } from './shadows';
import { soneiumTextStyles } from './textStyles';

export const soneiumTheme = (colorMode: 'light' | 'dark'): Partial<ChakraTheme> => ({
  styles: {
    global: {
      html: {},
      body: {
        color: '#ffffff',
        backgroundColor: '#000',
      },
    },
  },
  fonts: {},
  colors: colorMode === 'light' ? soneiumLight : soneiumDark,
  shadows: colorMode === 'light' ? soneiumShadows.light : soneiumShadows.dark,
  textStyles: soneiumTextStyles,
  components: {
    Button: {
      ...soneiumButtons,
      variants: {
        ...soneiumButtons.variants,
      },
    },
  },
});
