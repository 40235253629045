import { ChakraTheme } from '@chakra-ui/theme';
import { baseButtons } from './buttons';
import { arbitrumDark } from './colors/arbitrumDark';
import { arbitrumLight } from './colors/arbitrumLight';
import { arbitrumShadows } from './shadows';
import { arbitrumTextStyles } from './textStyles';
export const arbitrumTheme = (colorMode: 'light' | 'dark'): Partial<ChakraTheme> => ({
  styles: {
    global: {
      html: {},
      body: {
        color: '#ffffff',
        backgroundColor: '#000',
      },
    },
  },
  fonts: {},
  colors: colorMode === 'light' ? arbitrumLight : arbitrumDark,
  shadows: colorMode === 'light' ? arbitrumShadows.light : arbitrumShadows.dark,
  textStyles: arbitrumTextStyles,
  components: {
    Button: {
      ...baseButtons,
      variants: {
        ...baseButtons.variants,
      },
    },
  },
});
