import BlueRadial from '~/app/components/icons/DeFi/Misc/BlueRadial';

export const invisiblegradients = {
  light: {
    invisiblegradienthero: `
      repeating-conic-gradient(
        from 0deg,
        rgba(0, 119, 190, 0.2) 0deg 2deg, 
        rgba(255, 255, 240, 0.01) 2deg 15deg
      ),
      radial-gradient(
        circle at 50% 50%,
        rgba(255, 127, 0, 0.25) 0%,
        rgba(0, 119, 190, 0.1) 20%,
        rgba(192, 192, 192, 0) 40%
      )`,

    invisiblegradientcard: `
      repeating-conic-gradient(
        from 0deg,
        rgba(0, 119, 190, 0.15) 1deg 1deg, 
        rgba(255, 255, 240, 0.01) 2deg 15deg
      )`,

    invisiblegradient: `
      repeating-conic-gradient(
        from 0deg,
        rgba(0, 119, 190, 0.2) 0deg 2deg, 
        rgba(255, 255, 240, 0.9) 2deg 15deg
      ),
      radial-gradient(
        circle at 50% 50%,
        rgba(255, 127, 0, 0.1) 0%,
        rgba(192, 192, 192, 0.05) 50%,
        rgba(255, 255, 240, 0) 70%
      ),
      #F0F0F0`,
  },

  dark: {
    invisiblegradienthero: `
      repeating-conic-gradient(
        from 0deg,
        rgba(0, 119, 182, 0.2) 0deg 2deg, 
        rgba(14, 28, 42, 0.01) 2deg 15deg
      ),
      radial-gradient(
        circle at 50% 50%,
        rgba(163, 196, 217, 0.25) 0%,
        rgba(0, 119, 182, 0.1) 20%,
        rgba(14, 28, 42, 0) 40%
      )`,

    invisiblegradient: `
      repeating-conic-gradient(
        from 0deg,
        rgba(0, 119, 182, 0.5) 0deg 2deg, 
        rgba(14, 28, 42, 0.01) 2deg 15deg
      ),
      radial-gradient(
        circle at 50% 50%,
        rgba(163, 196, 217, 0.25) 0%,
        rgba(255, 140, 0, 0.05) 20%,
        rgba(14, 28, 42, 0) 40%
      )`,
  },
};
