import { mode } from '@chakra-ui/theme-tools';
import { globalButtons } from '../../global/buttons/mainbuttons';

export const optimismButtons = {
  variants: {
    


// 'linear-gradient(135deg, rgba(0, 0, 230, 1) 0%, #ff7777 100%)')(props),
// adjust this one 
    universalpawg: (props) => ({
      ...globalButtons.variants.universalpawg(props), 
      background: mode('linear-gradient(135deg, #fff 0%, #f0f0f0 100%)', 'linear-gradient(135deg, #233142 , #161D28)')(props),
      color: mode('rgba(255, 4, 32, 1)', 'rgba(248, 248, 248, 1)')(props),
      borderColor: mode('rgba(30, 30, 30, 0.1)', 'rgba(248, 248, 248, 0.75)')(props), 

      letterSpacing: '-0.5px',
      fontWeight: '400', 
      boxShadow: 'primaryButtonShadow', 
      // boxShadow: mode('primaryButtonShadow', '0 0 8px rgba(10, 10, 10)')(props), 
      _hover: {
        background: mode('linear-gradient(135deg, #f0f0f0 0%, #e8e8e8 100%)', 'linear-gradient(270deg, #233142, #ff7777))')(props), 
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1), inset 0 2px 3px rgba(0, 0, 0, 0.1)',
      },
      _disabled: {
        background: 'linear-gradient(135deg, #f0f0f0, #ff7777)', // this would be good on full opac
        color: '#555', 
        borderWidth: '0px', 
        boxShadow: 'primaryButtonShadow', 
      }
    }),
    cosmicpawg: (props) => ({
      ...globalButtons.variants.cosmicpawg(props), 
      background: 'linear-gradient(135deg, #ff3366 0%, #ff5555 50%, #ff7777 100%)',
      color: mode('rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)')(props),
      borderColor: '#ccc', 
      boxShadow:  'primaryButtonShadow', 

      fontWeight: '400', 
      letterSpacing: '-0.5px',
      _hover: {
        borderColor: '#fff', 
        background: 'linear-gradient(270deg, #ff2255 0%, #ff4444 50%, #ff6666 100%)',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1), inset 0 2px 3px rgba(0, 0, 0, 0.1)',
      },
      _disabled: {
        background: 'linear-gradient(135deg, #f0f0f0, #ff7777)', // this would be good on full opac
        color: '#555', 


      }
   
    }),
  }
};
