import { BoxProps, Divider, Flex, Popover, PopoverContent, useDisclosure, useTheme } from '@chakra-ui/react';
import SocialLinks from '../SocialLinks';
import AdditionalLinks from './AdditionalLinks';
import Documentation from './Documentation';
import DropdownTrigger from './DropdownTrigger';
interface Props extends BoxProps {
  mx?: string;
  marginRight?: string;
  marginLeft?: string;
}
function DropdownLinksMenu({
  mx,
  marginRight,
  marginLeft,
  ...props
}: Props) {
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const handleContentClick = () => {
    onClose();
  };
  const theme = useTheme();
  return <Popover trigger="hover" placement="bottom-start" isOpen={isOpen} onOpen={onOpen} onClose={onClose} {...props} data-sentry-element="Popover" data-sentry-component="DropdownLinksMenu" data-sentry-source-file="index.tsx">
      <DropdownTrigger isOpen={isOpen} onOpen={onOpen} onClose={onClose} data-sentry-element="DropdownTrigger" data-sentry-source-file="index.tsx" />

      <PopoverContent w="250px" position="relative" bg={theme.colors.insetComponentDropdown} zIndex="10" boxShadow="insetComponentShadow" onClick={handleContentClick} borderRadius="24px" px="3" data-sentry-element="PopoverContent" data-sentry-source-file="index.tsx">
        <AdditionalLinks data-sentry-element="AdditionalLinks" data-sentry-source-file="index.tsx" />

        <Documentation data-sentry-element="Documentation" data-sentry-source-file="index.tsx" />

        <Divider my="1" data-sentry-element="Divider" data-sentry-source-file="index.tsx" />
        <Flex direction="column" w="100%" align="flex-start" justify="flex-start" py="4" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
          <Flex w="100%" px="1" justify="space-between" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
            <SocialLinks data-sentry-element="SocialLinks" data-sentry-source-file="index.tsx" />
          </Flex>
        </Flex>
      </PopoverContent>
    </Popover>;
}
export default DropdownLinksMenu;