import React from 'react';
import { Button } from '@chakra-ui/react';
interface WrongNetworkProps {
  openChainModal: () => void;
  buttonText?: string;
}
export const WrongNetwork: React.FC<WrongNetworkProps> = ({
  openChainModal,
  buttonText = 'Wrong network'
}) => <Button background="red911" borderRadius="16px" _hover={{
  background: 'red',
  borderColor: 'white'
}} fontSize="14px" fontWeight="400" border="1px solid" borderColor="border" onClick={openChainModal} type="button" aria-label="Open Select Chain Modal" data-sentry-element="Button" data-sentry-component="WrongNetwork" data-sentry-source-file="WrongNetwork.tsx">
    {buttonText}
  </Button>;