import { mode } from '@chakra-ui/theme-tools';

export const Modal = {
  parts: ['overlay', 'dialog', 'content'],
  baseStyle: (props) => ({
    overlay: {
      bg: mode('rgba(0, 0, 0, 0.5)', 'darkgradient.700')(props),
    },
    dialog: {
      background: 'outsetComponent',

      borderWidth: '1px',
      borderColor: 'tableborder',
      borderRadius: '18px',
      paddingX: '1px',
      paddingY: '1px',
      boxShadow: mode(
        '0 0 8px -4px #a0aec0, 0 0 24px var(--chakra-colors-gray-200), 0 0 3px 1px #e2e8f0',
        '0 0 48px var(--chakra-colors-highlight)',
      )(props),
    },
    header: {
      px: '4',
      fontSize: 'xl',
      color: 'text.100',
      userSelect: 'none',
    },
    body: {
      px: '2',
      mb: '1',
      borderRadius: '18px',
    },
    headline: {
      fontSize: '24px',
      fontWeight: '500',
      color: 'text.100',
    },
    subheadline: {
      fontSize: '14px',
      color: 'text.100',
    },
    closeButton: {
      position: 'absolute',
      top: '1',
      right: '1',
      color: 'text.100',
    },
  }),
};
