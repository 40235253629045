'use client';

import Link from 'next/link';
import DiscordSimple from '~/app/components/icons/Simple/DiscordSimple';
import { useTheme } from '@chakra-ui/react';
import MediumSimple from '~/app/components/icons/Simple/MediumSimple';
import RedditSimple from '~/app/components/icons/Simple/RedditSimple';
import TelegramSimple from '~/app/components/icons/Simple/TelegramSimple';
import TwitterSimple from '~/app/components/icons/Simple/TwitterSimple';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
const SocialLinks = () => {
  const networkConfig = useNetworkConfig();
  const theme = useTheme();
  return <>
      <Link href={networkConfig.socialLinks.discord} target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <DiscordSimple color={theme.colors.gray[50]} data-sentry-element="DiscordSimple" data-sentry-source-file="index.tsx" />
      </Link>
      <Link href={networkConfig.socialLinks.twitter} target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <TwitterSimple color={theme.colors.gray[50]} data-sentry-element="TwitterSimple" data-sentry-source-file="index.tsx" />
      </Link>
      {networkConfig.socialLinks.telegram && <Link href={networkConfig.socialLinks.telegram} target="_blank" rel="noopener noreferrer">
          <TelegramSimple color={theme.colors.gray[50]} />
        </Link>}
      {networkConfig.socialLinks.reddit && <Link href={networkConfig.socialLinks.reddit} target="_blank" rel="noopener noreferrer">
          <RedditSimple color={theme.colors.gray[50]} />
        </Link>}
      {networkConfig.socialLinks.medium && <Link href={networkConfig.socialLinks.medium} target="_blank" rel="noopener noreferrer">
          <MediumSimple color={theme.colors.gray[50]} />
        </Link>}
    </>;
};
export default SocialLinks;