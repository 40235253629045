import { modeGradients } from './modeGradients';
import { invisiblegradients } from './invisiblegradients';

export const modeLight = {
  ...modeGradients,
  ...invisiblegradients.light, 
  primary: '#dffe00',
  highlight: '#000',
  navbarselected: '#000',
  tabselected: '#000', 
  createposition: '#000', 

  tokeninputswap: '#333', 

  layoutbg: 'transparent', 
  uibackground: modeGradients.radial, 

  outsetComponent: 'linear-gradient(#fffff0, rgba(255, 250, 245, 1))',
  insetComponent: 'linear-gradient(135deg, rgba(255, 253, 245, 1), rgba(255, 245, 240, 1))',
  dashboardshell: 'modeGradients.radial',
  swaptokeninput: 'linear-gradient(135deg, #E1DED7, #EBE8E1)',

  insetComponentDropdown: 'linear-gradient(#fffff0, #f5f5f5)',

  border: '#a1a1a1',
  border2: 'rgba(51,51,51,0.2)',

  navbarbg: 'linear-gradient(#fffff0, rgba(255, 250, 245, 1))', 
  subnav: 'linear-gradient(#fffff0, rgba(255, 250, 245, 1))', 
  navbarborder: 'rgba(0, 0, 0, 0.9)',

  primarybutton: 'linear-gradient(to right, #DFFE00, #60FE00)',

settingstextgradient: 'linear-gradient(to top, #000, #000)'

, 


  mastheadtextgradient: 'linear-gradient(to bottom right, #36a366, #32CD32, #60FE00, #32CD32, #36a366)',

  dextone: {
    100: '#DFFE00',
    150: '#E5FF33',
    200: '#EBFF66',
    250: '#F1FF99',
    300: '#F7FFCC',
    400: '#FCFFE5',
    500: '#FDFFED',
    600: '#FEFFF4',
    700: '#FEFFFA',
    800: '#FEFFFD',
    900: '#FFFFFF',
  },
};
