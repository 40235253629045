import { Flex, Switch, useTheme } from '@chakra-ui/react';
import { useExpertModeManager } from 'state/user/hooks';
import { TestTubeDiagonalIcon } from 'lucide-react';
import TooltipLabel from '../../tooltips/Label';
const ExpertMode = () => {
  const [expertMode, toggleSetExpertMode] = useExpertModeManager();
  const theme = useTheme();
  return <>
      <Flex align="center" justify="space-between" w="100%" my="3" data-sentry-element="Flex" data-sentry-source-file="ExpertMode.tsx">
        <TooltipLabel icon={TestTubeDiagonalIcon} iconSize={14} tooltipText="Activate expert mode to utilize atomized tick spacing when creating a V3 liquidity position" label="Expert Mode" color={theme.colors.gray[100]} data-sentry-element="TooltipLabel" data-sentry-source-file="ExpertMode.tsx" />

        <Switch id="expert-mode" isChecked={expertMode} colorScheme="grayAlpha" onChange={() => toggleSetExpertMode()} ml="12px" data-sentry-element="Switch" data-sentry-source-file="ExpertMode.tsx" />
      </Flex>
    </>;
};
export default ExpertMode;