import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  table: {
    fontVariantNumeric: 'lining-nums tabular-nums',
    borderCollapse: 'separate',
    borderSpacing: 0,
    width: 'full',
  },
  th: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    textTransform: 'uppercase',

    letterSpacing: 'wider',
    textAlign: 'start',
  },
  td: {
    textAlign: 'start',
  },
  caption: {
    mt: 4,
    fontFamily: 'heading',
    textAlign: 'center',
    fontWeight: 'medium',
  },
}));

const newTable = definePartsStyle((props) => ({
  table: {
    background: 'insetComponent', 
    boxShadow: 'insetComponentShadow', 
    borderRadius: '16px',
    borderWidth: '0px', 
    overflow: 'hidden',
    padding: '12px', 
    borderColor: mode('insetComponent', 'gray.700')(props),
  },
  thead: {
    bg: mode('transparent', 'transparent')(props),
    fontSize: '18px', 
    fontWeight: '500', 


  },
  th: {
    color: mode('gray.100', 'gray.100')(props),
    fontSize: '14px',
    fontWeight: '500',
    paddingX: '3',
    marginTop: '8px', 
    borderBottomWidth: '1px',
    borderColor: mode('rgba(30, 30, 30, 0.15)', 'highlight')(props),
  },
  tr: {
    _hover: {
      bg: 'alphaGradient.200', 
    }, 
  },
  td: {
    color: mode('gray.100', 'gray.200')(props),
    fontSize: '14px',
    padding: '3',
    borderBottom: '1px',
    borderColor: mode('gray.100', 'gray.700')(props),
 
  },
  tfoot: {
display: 'none', 

  }, 

  caption: {
    color: mode('gray.100', 'gray.400')(props),
    fontSize: 'sm',
    padding: '2',
  },
}));

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { newTable },
  defaultProps: {
    variant: 'newTable',
  },
});

export const TableContainer = {
  baseStyle: {
    overflow: 'auto',
    width: '100%',
    border: '1px', 
    padding: '8px', 
    boxShadow: 'componentGlow', 
  },
};