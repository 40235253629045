import { arbitrumGradients } from './arbitrumGradients';
import { invisiblegradients } from './invisiblegradients';

// primary, highlight, border, border2, navbarbg, navbarborder, outsetcomponent, insetcomponent,
// tablebg, primarybutton, tokeninput, uibackground, mastheadtextgradient, settingstextgradient, dextone

export const arbitrumDark = {
  ...arbitrumGradients,
  ...invisiblegradients.dark,
  primary: '#0096D0',
  highlight: '#C7D9E8',
  navbarselected: '#0096D0',
  tabselected: '#fff',
  tokeninputswap: '#fff',
  createposition: '#fff',

  border: '#C7D9E8',
  border2: 'rgba(199, 217, 232, 0.5)',

  navbarbg: '#152433',
  subnav: '#152433',
  navbarborder: '#0096D0',

  outsetComponent: 'linear-gradient(180deg, #152433 70%, #1A2A3A 85%, #1F3141 100%)',
  insetComponent: `linear-gradient(135deg, #0C1721, #152433)`,
  insetComponentDropdown: `linear-gradient(135deg, #0C1721, #152433)`,
  uibackground: `
  radial-gradient(100% 100% at 50% 50%, #0D121A 0%, transparent 100%),
  radial-gradient(80% 80% at 80% 80%, #0B1017 0%, transparent 100%),
  radial-gradient(70% 70% at 20% 20%, #090E14 0%, transparent 100%),
  radial-gradient(60% 60% at 40% 60%, #070C11 0%, transparent 100%),
  radial-gradient(50% 50% at 60% 30%, #050A0E 0%, transparent 100%),
  radial-gradient(at 0% 0%, rgba(0, 150, 208, 0.5) 0%, transparent 50%),
  radial-gradient(at 100% 0%, rgba(0, 150, 208, 0.3) 0%, transparent 25%),
  radial-gradient(at 10% 10%, rgba(0, 150, 208, 0.4) 0%, transparent 50%),
  radial-gradient(at 90% 100%, rgba(0, 150, 208, 0.5) 0%, transparent 25%),
  radial-gradient(at 0% 80%, rgba(0, 150, 208, 0.5) 0%, transparent 25%),
  radial-gradient(at 0% 100%, rgba(0, 150, 208, 0.25) 0%, transparent 25%),
  radial-gradient(at 100% 100%, rgba(0, 150, 208, 0.35) 0%, transparent 50%),
  #050A0E`,

  dashboardshell: 'transparent',
  tablebg:
    'linear-gradient(135deg, rgba(21, 36, 51, 0.65), rgba(0, 150, 208, 0.75), rgba(21, 36, 51, 0.65))',

  primarybutton: 'linear-gradient(180deg, #0096D0 15%, #00A8E8 70%, #19B3ED 100%)',

  tokeninput: 'rgba(199, 217, 232, 0.25)',

  mastheadtextgradient: `linear-gradient(
    135deg,
    #0096D0 0%,
    #1AA3D8 25%,
    #33B0E0 50%,
    #4CBDE8 75%,
    #66CAF0 90%,
    #FFFFFF 100%
  )`,

  settingstextgradient: `linear-gradient(
     135deg,
     #0096D0 0%,
     #4AA3D2 25%,
     #87CEEB 50%,
     #C7D9E8 75%,
     #FFFFFF 100%
   )`,

  dextone: {
    100: '#C7D9E8',
    200: '#96B7D0',
    300: '#6595B8',
    400: '#FFFFFF',
    500: '#035388',
    600: '#023D66',
    700: '#012744',
    800: '#001122',
    900: '#000B10',
  },
};

// Updated RGBA values
// primaryrgba: 'rgba(0, 150, 208, 1)',
// highlightrgba: 'rgba(199, 217, 232, 0.25)',
// tealrgba: 'rgba(0, 150, 208, 1)',
// orangergba: 'rgba(255, 140, 0, 0.1)'
