'use client';

import { ApolloProvider } from '@apollo/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { WagmiProvider } from 'wagmi';
import { useApollo } from '~/apollo/client';
import AppToast from '~/app/components/toast/AppToast';
import { wagmiConfig } from '~/lib/global/connection';
import { store } from '~/state';
import RainbowKitWithTheme from './RainbowKitWithTheme';
import { ThemedChakraProvider } from './ThemedChakraProvider';
import Compose from '~/components/providers/Compose';
import 'animate.css';
import { ThemeProvider as ColorThemeProvider } from 'next-themes';
import { ProviderWithProps } from '~/components/providers/Compose';
import { TokensProvider } from '~/lib/global/useToken';
import { useTranslations } from 'next-intl';
const queryClient = new QueryClient();
export default function Providers({
  children
}) {
  const client = useApollo();
  const t = useTranslations();
  if (!client) {
    return <div>{t('Loading')}...</div>;
  }
  const AppProviders: ProviderWithProps[] = [[TokensProvider, {}]
  // [UserDataProvider, {}],
  // [BlockNumberProvider, {}],
  ];
  return <ReduxProvider store={store} data-sentry-element="ReduxProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="providers.tsx">
        <WagmiProvider config={wagmiConfig} data-sentry-element="WagmiProvider" data-sentry-source-file="providers.tsx">
          <ColorThemeProvider defaultTheme="dark" attribute="class" enableSystem={false} data-sentry-element="ColorThemeProvider" data-sentry-source-file="providers.tsx">
            <ThemedChakraProvider data-sentry-element="ThemedChakraProvider" data-sentry-source-file="providers.tsx">
              <ApolloProvider client={client} data-sentry-element="ApolloProvider" data-sentry-source-file="providers.tsx">
                <AppToast data-sentry-element="AppToast" data-sentry-source-file="providers.tsx">
                  <Compose providers={AppProviders} data-sentry-element="Compose" data-sentry-source-file="providers.tsx">
                    <RainbowKitWithTheme data-sentry-element="RainbowKitWithTheme" data-sentry-source-file="providers.tsx">{children}</RainbowKitWithTheme>
                  </Compose>
                </AppToast>
              </ApolloProvider>
            </ThemedChakraProvider>
          </ColorThemeProvider>
        </WagmiProvider>
      </QueryClientProvider>
    </ReduxProvider>;
}