
import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

  
  const baseStyle = definePartsStyle({
    control: {
      padding: 3,
      borderRadius: 12,
      borderWidth: 1,
      boxSize: 4,
      bg: 'transparent', 
      _checked: {
        bg: 'transparent',
        borderColor: 'highlight', 
        _hover: { 
          bg: 'transparent', 
          borderColor: 'highlight', 
        }
      }
    },
    icon: {
      color: 'highlight', 
      size: 4,
    },
  })
  

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })