import { mode } from '@chakra-ui/theme-tools';

export const Input = {
  baseStyle: {
    field: {
      borderRadius: '12px',
      color: 'text.100', 
      _focus: {
        borderColor: '#ccc'
      }

    },
  },
  parts: ['field'],
  variants: {
      filled: (props) => ({
      field: {
        borderRadius: '12px',
        color: 'text.100 !important',
        // background: 'alphaGradient.200 !important',
        bgColor: 'porcelainAlpha.150 !important',
        borderColor: 'transparent !important',
        boxShadow: 'input.unfocused',
        _hover: {
          borderColor:  mode('rgba(255, 255, 255, 0.1) !important', 'rgba(0, 10, 10, 0.1) !important')(props),
          bgColor: 'rgba(200, 200, 200, 0.15) !important',
        },
        _focus: {
          bgColor: 'porcelainAlpha.200 !important',
          borderColor:  mode('rgba(255, 255, 255, 0.2) !important', 'rgba(255, 255, 255, 0.05) !important')(props),
          boxShadow: 'input.focused',
        },
        _placeholder: {
          color: 'textAlpha.600 !important',
        },
      },
    }),
    tokensearch: (props) => ({
      field: {
        borderRadius: '12px',
        color: 'text.100 !important',
        // background: 'alphaGradient.200 !important',
        background: 'outsetComponent !important',
        borderColor:  mode('rgba(255, 255, 255, 0.1) !important', 'rgba(10, 10, 10, 0.25) !important')(props),
        border: '1px solid', 
        boxShadow: 'input.unfocused',
        _hover: {
          borderColor:  mode('rgba(255, 255, 255, 0.1) !important', 'rgba(0, 10, 10, 0.1) !important')(props),
          bgColor: 'rgba(200, 200, 200, 0.15) !important',
        },
        _focus: {
          borderColor:  mode('rgba(255, 255, 255, 0.2) !important', 'rgba(255, 255, 255, 0.05) !important')(props),
          boxShadow: 'input.focused',
        },
        _placeholder: {
          color: 'textAlpha.600 !important',
        },
      },
    }),
    
  },
};