'use client';

import { useMediaQuery } from '@chakra-ui/react';

const useIsLarge = (): boolean => {
  const [isLarge] = useMediaQuery('(min-width: 1000px)');
  return isLarge;
};

export default useIsLarge;
