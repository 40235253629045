'use client';

import React from 'react';
import { Flex } from '@chakra-ui/react';
import NavbarLink from '../../components/NavbarLink';
import Link from 'next/link';
import DropdownLinksMenu from '../../components/DropdownLinksMenu';
import { usePathname } from 'next/navigation';
import { getDesktopNavItems } from '~/lib/global/chain';
import useIsLarge from '~/app/components/wrapper/useIsLarge';
import AnimatedNavbarLink from '../../components/AnimatedLink';
import { useTranslations } from 'next-intl';
export default function NavbarDesktop() {
  const pathname = usePathname();
  const desktopNavItems = getDesktopNavItems();
  const isLarge = useIsLarge();
  const t = useTranslations();
  return <>
      {isLarge && <Flex margin="0px" zIndex="4" height="100%">
          <Flex height="100%" alignItems="flex-end" justifyContent="flex-start" style={{
        cursor: 'pointer'
      }}>
            <Flex align="flex-end" gap="60px">
              {desktopNavItems.map(item => {
            const displayName = item.nameKey ? t(item.nameKey) : item.name;
            if (item.component === AnimatedNavbarLink) {
              return <AnimatedNavbarLink key={item.path} path={item.path}>
                      {displayName}
                    </AnimatedNavbarLink>;
            } else {
              const isSelected = pathname === item.path;
              return <NavbarLink key={item.path} selected={isSelected}>
                      <Link href={item.path}>{displayName}</Link>
                    </NavbarLink>;
            }
          })}
              <DropdownLinksMenu />
            </Flex>
          </Flex>
        </Flex>}
    </>;
}