import { extendTheme, ThemeConfig } from '@chakra-ui/react';

import { baseTheme } from './themes/chains/base/baseTheme';
import { Alert, Skeleton, Tooltip } from './themes/global/componentStyles';
import { radioTheme } from './themes/global/radio';
import { Input } from './themes/global/input';
import { Modal } from './themes/global/modal';
import { Card } from './themes/global/card';
import { Menu } from './themes/global/menu';
import { customTabs } from './themes/global/tabs';
import { checkboxTheme } from './themes/global/checkbox';
import { tableTheme, TableContainer } from './themes/global/table';
import { breakpoints } from './themes/global/breakpoints';
import { getColors } from './themes/global/colors/colors';
import { themesByChainId, config } from './themes/chains/themesByChainId';
import { globalClasses } from './themes/global/globalClasses';
import { globalShadows } from './themes/global/shadows';
import { Popover } from './themes/global/popover';
import { globalScrollbar } from './themes/global/scrollbar';
import { globalButtons } from './themes/global/buttons/mainbuttons';

export const defaultConfig = config;
export const chakraTheme = (chainId, colorMode) => {
  const themeOrThemeFunction = themesByChainId[chainId] || baseTheme;
  const colors = getColors(colorMode);

  const chainTheme = themesByChainId[chainId] || {};

  const theme =
    typeof themeOrThemeFunction === 'function'
      ? themeOrThemeFunction(colorMode)
      : themeOrThemeFunction;

  const shadows = colorMode === 'dark' ? globalShadows.dark : globalShadows.light;
  const featuredpools = colorMode === 'dark' ? theme.colors.highlight : '#111';

  return extendTheme({
    ...theme,
    config,
    breakpoints,
    shadows: {
      ...shadows,
      ...theme.shadows,
    },
    styles: {
      global: {
        ...(theme.styles?.global || {
          body: {
            borderColor: 'border',
            color: 'gray.300',
          },
        }),
        ...globalClasses,
        ...globalScrollbar,
      },
      html: { scrollBehavior: 'smooth' },
    },
    customUtilities: {
      backdropFilter: (value) => ({
        WebkitBackdropFilter: value,
        backdropFilter: value,
      }),
    },
    colors: {
      ...colors,
      ...theme.colors,
      featuredpools,
    },
    components: {
      ...theme.components,
      Button: {
        ...globalButtons,
        ...theme.components.Button,
        variants: {
          ...globalButtons.variants,
          ...theme.components.Button.variants,
        },
      },
      Modal: Modal,
      Menu: Menu,
      Card: Card,
      Checkbox: checkboxTheme,
      Skeleton: Skeleton,
      Table: tableTheme,
      TableContainer,
      Tooltip: Tooltip,
      Alert: Alert,
      Radio: radioTheme,
      Popover: Popover,
      Input: Input,
      Divider: {
        baseStyle: {
          borderWidth: '1px',
          boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 1)',
          _dark: {
            boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
          },
        },
      },
      Tabs: customTabs,
      Text: {
        baseStyle: {
          userSelect: 'none',
          color: 'text.100',
        },
        variants: {
          // cardHeadline: {
          //   fontWeight: 'semibold',
          //   fontSize: 'xl',
          //   color: 'white',
          // },
        },
      },
    },
  });
};
