import { Token } from '@baseswapfi/sdk-core';
import { createAction } from '@reduxjs/toolkit';
import { GqlToken } from '~/apollo/generated/graphql-codegen-generated';

export interface SerializedPair {
  token0: Token;
  token1: Token;
}

export const setZapDisabled = createAction<boolean>('user/setZapDisabled');
export const updateHideClosedPositions = createAction<{ userHideClosedPositions: boolean }>(
  'user/updateHideClosedPositions',
);
export const updateUserExpertMode = createAction<{ userExpertMode: boolean }>(
  'user/updateUserExpertMode',
);
export const updateUserShowRoute = createAction<{ userShowRoute: boolean }>(
  'user/updateUserShowRoute',
);

export const updateUserSwapChartMode = createAction<{ userSwapChartMode: boolean }>(
  'user/updateUserSwapChartMode'
);

export const updateUserSingleHopOnly = createAction<{ userSingleHopOnly: boolean }>(
  'user/updateUserSingleHopOnly',
);
export const updateUserSlippageTolerance = createAction<{ userSlippageTolerance: number }>(
  'user/updateUserSlippageTolerance',
);

export const updateGasPrice = createAction<{ gasPrice: string }>('user/updateGasPrice');

export const updateUserDeadline = createAction<{ userDeadline: number }>('user/updateUserDeadline');

export const addSerializedPair = createAction<{ serializedPair: SerializedPair }>(
  'user/addSerializedPair',
);
export const removeSerializedPair = createAction<{
  chainId: number;
  tokenAAddress: string;
  tokenBAddress: string;
}>('user/removeSerializedPair');

export const addPinnedToken = createAction<{
  chainId: number;
  token: GqlToken;
}>('user/addPinnedToken');

export const removePinnedToken = createAction<{
  chainId: number;
  token: GqlToken;
}>('user/removePinnedToken');
