import React, { forwardRef } from 'react';
interface IconProps {
  color?: string;
  size?: string | number;
}
const DocumentSimple = forwardRef<SVGElement, IconProps>(({
  color = '#ccc',
  size = 20,
  ...rest
}, ref) => {
  return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0,0,256,256">
        <g fill={color} fillRule="nonzero" stroke="none" strokeWidth="0.5" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none">
          <g transform="scale(5.12,5.12)">
            <path d="M15,5c-6.10156,0 -10.30469,1.99219 -10.40625,2.09375c-0.39844,0.19922 -0.59375,0.50781 -0.59375,0.90625v3h-1c-1.69922,0 -3,1.30078 -3,3v26c0,1.69922 1.30078,3 3,3h19.78125c0.54297,0.60547 1.3125,1 2.21875,1c0.90625,0 1.67578,-0.39453 2.21875,-1h19.78125c1.69922,0 3,-1.30078 3,-3v-26c0,-1.69922 -1.30078,-3 -3,-3h-1v-3c0,-0.39844 -0.19531,-0.70703 -0.59375,-0.90625c-0.10156,-0.10156 -4.30469,-2.09375 -10.40625,-2.09375c-5.10156,0 -8.80078,1.40625 -10,1.90625c-1.19922,-0.5 -4.89844,-1.90625 -10,-1.90625zM15,7c4.39844,0 7.69922,1.08594 9,1.6875v28.90625c-1.69922,-0.60156 -4.89844,-1.5 -9,-1.5c-4.10156,0 -7.30078,0.89844 -9,1.5v-28.90625c1.30078,-0.60156 4.60156,-1.6875 9,-1.6875zM35,7c4.39844,0 7.69922,1.08594 9,1.6875v28.90625c-1.69922,-0.60156 -4.89844,-1.5 -9,-1.5c-4.10156,0 -7.30078,0.89844 -9,1.5v-28.90625c1.30078,-0.60156 4.60156,-1.6875 9,-1.6875zM35,12c-1.10547,0 -2,0.89453 -2,2c0,1.10547 0.89453,2 2,2c1.10547,0 2,-0.89453 2,-2c0,-1.10547 -0.89453,-2 -2,-2zM31.8125,19c-0.55078,0.05078 -0.95703,0.54297 -0.90625,1.09375c0.05078,0.55078 0.54297,0.95703 1.09375,0.90625h1v10h4v-11c0,-0.60156 -0.39844,-1 -1,-1h-4c-0.03125,0 -0.0625,0 -0.09375,0c-0.03125,0 -0.0625,0 -0.09375,0z"></path>
          </g>
        </g>
      </svg>;
});
DocumentSimple.displayName = 'DocumentSimple';
export default DocumentSimple;