'use client';

import React from 'react';
import { Box } from '@chakra-ui/react';
import Link from 'next/link';
import DropdownLinkTemplate from './DropdownLinkTemplate';
import { getDesktopDropdownItems } from '~/lib/global/chain';
function AdditionalLinks() {
  const dropdownItems = getDesktopDropdownItems();
  return <Box data-sentry-element="Box" data-sentry-component="AdditionalLinks" data-sentry-source-file="AdditionalLinks.tsx">
      {dropdownItems.map(item => <Link key={item.path} href={item.path} {...item.isExternal ? {
      target: '_blank',
      rel: 'noopener noreferrer'
    } : {}}>
          <DropdownLinkTemplate icon={item.icon} text={item.name} />
        </Link>)}
    </Box>;
}
export default AdditionalLinks;