export const globalScrollbar = {
 // width for both themes
 '::-webkit-scrollbar': {
   width: '6px',
 },


 // track style
 '::-webkit-scrollbar-track': {
   boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
 },

 // thumb default + light  
 '::-webkit-scrollbar-thumb': {
   background: 'scrollbar',
   borderRadius: '12px',
   transition: 'background 0.3s ease-in-out',
 },

 // thumb hover (default - doesnt look like configing it by theme works. )
 '::-webkit-scrollbar-thumb:hover': {
   background: 'highlight',
 },



 // dark theme thumb - obv same as above but leaving here for future config if needed. 
 '.chakra-ui-dark ::-webkit-scrollbar-thumb': {
   background: 'scrollbar',

 },
 


};