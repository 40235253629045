import { mode } from '@chakra-ui/theme-tools';
import { globalButtons } from "../../global/buttons/mainbuttons";
const primary = '#DFFE00';

export const modeButtons = {
  variants: {
    primary: (props) => ({

      ...globalButtons.variants.primary(props),
      color: mode('#000', '#000')(props),
      _hover: {
        ...globalButtons.variants.primary(props)._hover,
        borderColor: mode('#fff', '#fff')(props),
        background: 'buttongroup', 
        boxShadow: 'none', 
      },
      _active: {
        ...globalButtons.variants.primary(props)._active,
        color: mode('#000', '#000')(props),
      },
    }),
    universalpawg: (props) => ({
      ...globalButtons.variants.universalpawg(props), 
      background: mode('buttongroup', 'linear-gradient(135deg, #000000 0%, #1a1a1a 100%)')(props), 
      fontWeight: '400', 
      color: mode('#000', '#dffe00')(props), 
      borderColor:  mode('#fff', '#dffe00')(props), 
      boxShadow: 'lifted', 
      borderRadius: '12px', 

      _hover: {
        background: mode('linear-gradient(to right, #dffe00, #60FE00)', 'linear-gradient(135deg, #1a1a1a 0%, #000000 100%)')(props), 
        boxShadow: 'none',

      },
      _disabled: {
        background: 'linear-gradient(135deg, #f0f0f0, #dffe00)', 
        color: '#555', 
        opacity: '0.4', 
      }
    }),
    
    cosmicpawg: (props) => ({
      ...globalButtons.variants.cosmicpawg(props), 
      background:  mode('linear-gradient(to bottom right, #60FE00, #dffe00)', 'buttongroup')(props), 
     
      color: mode('#000', '#000')(props), 
      fontWeight: '400',
      border:  mode('1px solid #fff', '1px solid #fff')(props), 
      letterSpacing: '0px',
      borderColor: '#fff', 
      borderRadius: '12px', 
      boxShadow: 'lifted',
      _hover: {
        background: 'linear-gradient(135deg, #c0c0c0 30%, #000000 100%)',
        boxShadow: '0 0 15px rgba(192, 192, 192, 0.4), inset 0 0 8px rgba(192, 192, 192, 0.3)',
        borderColor: '#dffe00',
      },
      _disabled: {
        background: 'linear-gradient(135deg, #000000, #dffe00)', 
        color: '#777', 
        opacity: '0.4', 
      }
    }),
  }
};
