import { HStack, Skeleton, StackProps, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
interface Props extends StackProps {
  loading: boolean;
  value: string;
  label: string;
  extra?: ReactNode;
}
export function SubNavBarStat({
  label,
  value,
  loading,
  extra,
  ...rest
}: Props) {
  return <HStack mr={3} {...rest} data-sentry-element="HStack" data-sentry-component="SubNavBarStat" data-sentry-source-file="SubNavBarStat.tsx">
      <Text color="gray.300" fontSize={{
      base: 'sm',
      lg: '14px'
    }} data-sentry-element="Text" data-sentry-source-file="SubNavBarStat.tsx">
        {label}:
      </Text>
      {loading ? <Skeleton height="16px" width="54px" /> : <Text color="text.100" fontWeight="500" fontSize={{
      base: 'sm',
      lg: '14px'
    }}>
          {value}
        </Text>}

      {extra}
    </HStack>;
}