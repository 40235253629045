import React from 'react';
import { Switch, Flex, Text, useTheme } from '@chakra-ui/react';
import { ChartLineIcon } from 'lucide-react';
import TooltipLabel from '../../tooltips/Label';
import { useSwapChartMode } from '~/state/user/hooks';
const SwapChartToggle = () => {
  const [swapChartMode, toggleSwapChartMode] = useSwapChartMode();
  const theme = useTheme();
  return <Flex align="center" justify="space-between" w="100%" my="3" data-sentry-element="Flex" data-sentry-component="SwapChartToggle" data-sentry-source-file="SwapChartToggle.tsx">
      <TooltipLabel icon={ChartLineIcon} iconSize={14} tooltipText="Toggle your viewmode on the Swap page" label="Swap Chart" color={theme.colors.gray[100]} data-sentry-element="TooltipLabel" data-sentry-source-file="SwapChartToggle.tsx" />
      <Switch id="swap-chart-mode" isChecked={swapChartMode} onChange={toggleSwapChartMode} colorScheme="grayAlpha" data-sentry-element="Switch" data-sentry-source-file="SwapChartToggle.tsx" />
    </Flex>;
};
export default SwapChartToggle;