'use client';

import { Flex, useTheme } from '@chakra-ui/react';
import Link from 'next/link';
import { getMobileNavItems } from '~/lib/global/chain';
import PopoverLinks from './PopoverLinks';
const MobileFooter = () => {
  const mobileNavItems = getMobileNavItems();
  const theme = useTheme();
  return <>
      <Flex alignSelf="center" align="center" justify="center" px="2px" display={{
      base: 'flex',
      lg: 'none'
    }} data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
        <Flex position="fixed" bg="outsetComponent" width="98%" alignSelf="center" boxShadow="insetComponentShadow" borderRadius="12px" px="4px" margin="4px" pl="4" align="center" justify="space-between" zIndex="4" bottom="0" height="45px" color={theme.colors.text[100]} data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
          {mobileNavItems.map(item => <Link href={item.path} key={item.path}>
              {item.name}
            </Link>)}

          <PopoverLinks data-sentry-element="PopoverLinks" data-sentry-source-file="Footer.tsx" />
        </Flex>
      </Flex>
    </>;
};
export default MobileFooter;