import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import mint from './mint/reducer';
import mintV3 from './mint/v3/reducer';
import user from './user/reducer';

// const persistedReducers = {
//   user,
//   transactions,
//   signatures,
//   lists,
// }

export const store = configureStore({
  reducer: {
    mint,
    mintV3,
    user,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
