
export const optimismGradients = {


dexgradient: {
 100: 'linear-gradient(to right, rgba(229, 45, 39, 0.55), rgba(255, 140, 0, 0.5), rgba(229, 45, 39, 0.5))',
 200: 'radial-gradient(circle at top left, #ff0420, #e52d27, #4B0082)',
 300: 'linear-gradient(to right, #e52d27, #FF8C00, #e52d27, #FF8C00)',
 400: 'linear-gradient(135deg, #e52d27, #DAA520, #ff0420, #DAA520)',
 500: 'linear-gradient(135deg, #e52d27, #DAA520, #ff0420, #DAA520)',
 600: 'linear-gradient(135deg, #e52d27, #DAA520, #ff0420, #DAA520)',
 700: 'linear-gradient(to right, #ff0420, #4B0082, #8A2BE2, #ff0420)',
 800: 'radial-gradient(circle at 100% 0%, #FF8C00, #e52d27 50%, #ff0420)',
 900: 'linear-gradient(to bottom right, #4B0082, #e52d27, #ff0420, #4B0082)'
},






// 200 400 100 


darkgradient: {
 100: 'linear-gradient(to bottom, #070707, #191919, #070707)',
 200: 'linear-gradient(to left, #020202, #191919, #020202)',
 300: 'linear-gradient(to bottom, #020202, #191919, #020202)',
 400: 'linear-gradient(to bottom, #111, #191919, #111)',
 500: 'linear-gradient(to top, #0A0A0C, #020202)',
 600: 'linear-gradient(to top, #1A1B1F, #0E0F11)',
 700: 'linear-gradient(to bottom, rgba(41, 41, 41, 0.85), rgba(2, 2, 2, 0.5))',
 900: ' linear-gradient(to bottom, #000, #111)',
},

cardgradient: {
 100: 'linear-gradient(to bottom, #020202, #191919, #020202)',
 200: 'linear-gradient(to top, #1A1B1F, #0E0F11)',
 700: 'linear-gradient(#000,#111)', 
},

scrollbar: 'radial-gradient(circle at top left, #ff7777, #ff3366, #ff0420)',
slidertrack: 'linear-gradient(270deg, #ff3366 0%, #ff5555 50%, #ff7777 100%)',
buttongroup: 'linear-gradient(135deg, #ff3366 0%, #ff5555 50%, #ff7777 100%)',


}